import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45deprecated_45locales_45global from "/app/middleware/01.deprecated-locales.global.ts";
import _02_45trailing_45slash_45global from "/app/middleware/02.trailing-slash.global.ts";
import activity_45tracker_45global from "/app/middleware/activity-tracker.global.ts";
import meta_45tags_45global from "/app/middleware/meta-tags.global.ts";
export const globalMiddleware = [
  validate,
  _01_45deprecated_45locales_45global,
  _02_45trailing_45slash_45global,
  activity_45tracker_45global,
  meta_45tags_45global
]
export const namedMiddleware = {}