
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

import 'dayjs/locale/en'
import 'dayjs/locale/de'
import 'dayjs/locale/fr'
import 'dayjs/locale/es'
import 'dayjs/locale/it'
import 'dayjs/locale/pt'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'


dayjs.extend(updateLocale)

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)
dayjs.extend(localizedFormat)



// defaultLocale: "en"


dayjs.updateLocale("en")
dayjs.locale('en')


export default dayjs
