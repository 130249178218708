import Base from './Base.vue';
import Link from './Link.vue';
import Anchor from './Anchor.vue';
import Regular from "./Regular.vue";
import ReadMore from './ReadMore.vue';
import Form from './Form.vue';
import Card from './Card.vue';

export default {
  Base,
  Link,
  Anchor,
  Regular,
  ReadMore,
  Form,
  Card,
}
