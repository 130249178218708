// services
import Cookie from './Cookie';

// utils
import { v4 as uuid, validate } from 'uuid';

// constants
import { COOKIE_KEYS } from '~/constants';

export default class UserId {
  public static get(): string {
    let value = Cookie.get(COOKIE_KEYS.USER_ID);

    if (!validate(value)) {
      value = uuid();
      UserId.set(value);
    }

    return value;
  }

  public static set(value: string) {
    Cookie.set(COOKIE_KEYS.USER_ID, value);
  }
}
