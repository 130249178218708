export const SUBSCRIBE = '/subscribe';
export const REPORT = '/report';
export const ACTIVITY = '/activity';
export const SEARCH = '/search';

export const HELP_VERSIONS = '/help-versions';
export const HELP_PAGES = '/help-pages';
export const HELP_PAGES_THREE = `${HELP_PAGES}/tree`;
export const HELP_PAGES_SEARCH = `${HELP_PAGES}/search`;
export const HELP_PAGES_PDF = `${HELP_PAGES}/pdf/download`;
export const HELP_PAGES_FEEDBACK = `${HELP_PAGES}/feedback`;

export const DEMO = '/demo';
export const DEMO_SHORT = '/demo-short';
export const BECOME_PARTNER = '/become-partner';
export const CONNECT_CHANNEL_MANAGER = '/connect-channel-manager';
export const WHITE_PAPER = '/white-paper';
export const OFFLINE_BUNDLE = '/offline-bundles/request';

export const VSA = '/vsa';
export const VSA_TRACK_DOWNLOAD = `${VSA}/track-download`;

export const LICENSES = '/licenses';
export const LICENSES_DOWNLOAD = `${LICENSES}/download`;
export const LICENSES_DOWNLOAD_URL = `${LICENSES_DOWNLOAD}/url`;

export const PRE_RECORDED_DEMO = '/pre-recorded-demo';
export const PRE_RECORDED_DEMO_USER_SESSIONS = `${PRE_RECORDED_DEMO}/user-sessions`;
export const PRE_RECORDED_DEMO_ACCESS_LINK = `${PRE_RECORDED_DEMO}/access-link`;
export const PRE_RECORDED_DEMO_ACCESS_LINK_VERIFY = `${PRE_RECORDED_DEMO_ACCESS_LINK}/verify`;

export const JOB_POSTS = 'job-posts';
export const JOB_POSTS_LOCATIONS = `${JOB_POSTS}/locations`;
export const JOB_POSTS_APPLY = (id: string) => `/${JOB_POSTS}/${id}/apply`;

export const SERIAL_NUMBER_EXIST = (serialNumber: string) => `/ogou/servers/${serialNumber}/exists`;

export const AUTH = '/auth';
export const AUTH_TOKEN = `${AUTH}/token`;

export const USER_DATA = '/user-data';

export const SITEMAP = 'sitemap';
export const SIZER_ACTION = '/sizer-action';

export const JWT_ENCODE = '/jwt/submitted-params';
export const JWT_DECODE = '/api/jwt/decode';
