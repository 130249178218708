<script setup lang="ts">
const props = defineProps<{ name: string }>();
const component = defineAsyncComponent(
  () => import(`~/assets/svg/${props.name}.svg?component`),
);
</script>

<template>
  <component v-if="component" :is="component" />
</template>
