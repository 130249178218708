import { UserId } from '~/services';

export class GTM {
  constructor() {
    this.push({ event: 'gtm.js', 'gtm.start': Date.now() });
  }

  private get dataLayer() {
    if (!('dataLayer' in window) || !Array.isArray(window.dataLayer)) {
      window.dataLayer = [];
    }

    return window.dataLayer;
  }

  public push(data: object): void {
    this.dataLayer.push({ ...data, lead_id: UserId.get() });
  }

  public trackView(path: string, screenName: string): void {
    this.push({
      event: 'content-view',
      'content-name': path,
      'content-view-name': screenName,
    });
  }
}

export default defineNuxtPlugin(() => {
  const gtm = new GTM();
  const router = useRouter();

  router.afterEach(to => {
    const name = <string>to.name;
    let url = router.options.history.base;

    if (!url.endsWith('/')) {
      url += '/';
    }

    url += to.fullPath.startsWith('/')
      ? to.fullPath.substring(1)
      : to.fullPath;

    gtm.trackView(url, name);
  });

  return {
    provide: {
      gtm,
    },
  };
});
