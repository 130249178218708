import Cookie from '~/services/Cookie';
import { useCookieLocale } from '#i18n';

export default defineNuxtPlugin(nuxt => {
  const cookieLocale = useCookieLocale();
  const i18nLocale = nuxt.$i18n.locale.value;

  if (!cookieLocale.value) {
    Cookie.set('locale', i18nLocale);
  }
});
