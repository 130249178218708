// dependencies
import { ofetch } from 'ofetch';

// stores
import { useCommonStore } from '~/stores/common';

// composables
import { storeToRefs } from 'pinia';

// exceptions
import { FormValidationError } from '~/exceptions';

// utils
import get from 'lodash.get';
import { inRange, isString, isObject } from '~/utils';

// services
import { UserId } from '~/services';

// types
import type { FetchContext } from 'ofetch';
import type { FormSubmitResponse } from '~/types/api-responses';

export default defineNuxtPlugin(() => {
  const nuxt = useNuxtApp();
  const { locale } = nuxt.$i18n;
  const { ipInfo } = storeToRefs(useCommonStore());
  const { public: { apiIpInfo } } = useRuntimeConfig();

  globalThis.$fetch = ofetch.create({
    onRequest({ request, options: { headers } }) {
      if (
        isString(request) && (
          '_nuxt'.includes(request) ||
          [apiIpInfo].includes(request)
        )
      ) return;

      headers.set('locale', locale.value || 'en');

      if (import.meta.client) {
        headers.set('user_id', UserId.get());
        headers.set('user_ip', ipInfo.value.ip);
      }
    },

    // https://github.com/unjs/ofetch#-access-to-raw-response
    async onResponseError({ response }: FetchContext): Promise<void> {
      if (!response) return;

      if (
        inRange(response.status, 400, 499) &&
        isObject(get(response, '_data.errors'))
      ) {
        throw new FormValidationError(
          response.status,
          (response._data as FormSubmitResponse).errors,
        );
      }
    },
  });
});
