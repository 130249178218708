export function getSdk(client, withWrapper = (action, _operationName, _operationType) => action()) {
  return {
    GetAuthor: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query GetAuthor($slug: String) {
  author(slug: $slug) {
    id
    slug

    created_at
    updated_at

    data {
      name
      description

      data {
        avatar
        reddit
        twitter
        facebook
        linkedin
      }

      seo {
        image
        title
        description
        robots
        og_type
      }
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetAuthor', 'query'),
    PostType: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`enum PostType {
  POST
  RESOURCE
  NEWSROOM
  GUIDE
  SECURITY
  LEGAL
  INSTALLATION
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PostType', 'query'),
    PostStatus: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`enum PostStatus {
  PUBLISHED
  SCHEDULED
  DRAFT
  INACTIVE
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PostStatus', 'query'),
    BlogArticle: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query BlogArticle($slug: String) {
  blog_article(slug: $slug) {
    id
    type
    status
    slug
    data {
      title
      content
      preview
      content_table {
        href
        title
      }
      data {
        visible_for
        preview_time_to_read
        preview_picture_url
        preview_type
        post_subtitle
        location
        source_links
        show_sidebar_nav
      }
      seo {
        image
        title
        description
        robots
        og_type
      }
    }
    publish_at
    updated_at
    authors {
      id
      slug
      data {
        name
        description
        data {
          avatar
          reddit
          twitter
          facebook
          linkedin
        }
      }
    }
    categories {
      id
      type
      slug
      data {
        title
      }
    }

    relatedArticles(filter: {statuses: PUBLISHED, types: POST}) {
      id
      type
      status
      slug

      data {
        title
        content
        preview

        data {
          post_subtitle
          preview_time_to_read
          preview_picture_url
        }
        title
      }
      categories {
        slug
        data {
          title
        }
      }

      publish_at

      authors {
        data {
          name
        }
      }
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'BlogArticle', 'query'),
    BlogArticlesPreviewsFiltered: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query BlogArticlesPreviewsFiltered(
  $first: Int = 10
  $page: Int
  $types: [PostType!]! = POST
  $statuses: [PostStatus!]! = PUBLISHED
  $category_slugs: Mixed = []
  $featured: Boolean = false
  $exclude: Mixed = []
) {
  blog_articles(
    first: $first
    page: $page
    types: $types
    statuses: $statuses
    hasCategories: { column: SLUG, operator: IN, value: $category_slugs }
    featured: $featured
    orderBy: { column: PUBLISH_AT, order: DESC }
    where: { column: "id", operator: NOT_IN, value: $exclude },
  ) {
    data {
      id

      type
      status
      slug

      data {
        title
        content
        preview

        data {
          preview_type
          preview_author_text
          preview_author_type
          preview_button_link
          preview_button_text
          preview_button_type
          preview_picture_url
          preview_author_image
          preview_time_to_read
          preview_button_action
          preview_company_logo_url
          preview_action_url
          preview_action_campaign
          preview_action_page

          visible_for
        }
      }

      created_at
      publish_at
      updated_at

      categories {
        id
        slug

        data {
          title
        }
      }

      authors {
        id
        slug

        data {
          name
          description

          data {
            avatar
            reddit
            twitter
            facebook
            linkedin
          }
        }
      }
    }

    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'BlogArticlesPreviewsFiltered', 'query'),
    BlogArticlesPreviews: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query BlogArticlesPreviews(
  $first: Int = 10
  $page: Int
  $types: [PostType!]! = POST
  $statuses: [PostStatus!]! = PUBLISHED
#  $category_slugs: Mixed = []
  $featured: Boolean = false
) {
  blog_articles(
    first: $first
    page: $page
    types: $types
    statuses: $statuses
#    hasCategories: { column: SLUG, operator: IN, value: $category_slugs }
    featured: $featured
    orderBy: { column: PUBLISH_AT, order: DESC }
  ) {
    data {
      id

      type
      status
      slug

      data {
        title
        content
        preview

        data {
          preview_type
          preview_author_text
          preview_author_type
          preview_button_link
          preview_button_text
          preview_button_type
          preview_picture_url
          preview_author_image
          preview_time_to_read
          preview_button_action
          preview_company_logo_url
          preview_action_url
          preview_action_campaign
          preview_action_page

          visible_for
        }
      }

      created_at
      publish_at
      updated_at

      categories {
        id
        slug

        data {
          title
        }
      }

      authors {
        id
        slug

        data {
          name
          description

          data {
            avatar
            reddit
            twitter
            facebook
            linkedin
          }
        }
      }
    }

    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'BlogArticlesPreviews', 'query'),
    BlogArticlesByAuthor: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query BlogArticlesByAuthor(
  $author_id: Mixed = 1,
  $page: Int,
) {
  blog_articles(
    first: 10
    statuses: PUBLISHED
    page: $page
    hasAuthors: { column: ID, operator: EQ, value: $author_id }
    orderBy: { column: PUBLISH_AT, order: DESC }
  ) {
    data {
      id
      type
      status
      slug

      data {
        title
        content
        preview
      }

      authors {
        id
        slug

        data {
          name
          description

          data {
            avatar
            reddit
            twitter
            facebook
            linkedin
          }
        }
      }

      categories {
        type
        slug

        data {
          title
        }
      }

      data {
        data {
          visible_for

          preview_type
          preview_author_text
          preview_author_type
          preview_button_link
          preview_button_text
          preview_button_type
          preview_picture_url
          preview_author_image
          preview_time_to_read
          preview_button_action
        }
      }

      publish_at
    }

    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'BlogArticlesByAuthor', 'query'),
    CaseStudiesArticle: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query CaseStudiesArticle($slug: String) {
  blog_article(slug: $slug) {
    id
    type
    status
    slug

    data {
      title
      content
      preview

      seo {
        image
        title
        description
        robots
        og_type
      }

      data {
        pdf_url
        company_logo_url
        company_logo_alt

        location
        industry

        company_description

        feedback_quote
        author_name
        author_position
      }
    }

    categories {
      type
      slug
      id

      data {
        title
      }
    }

    publish_at
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CaseStudiesArticle', 'query'),
    AnalystReportsArticle: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query AnalystReportsArticle($slug: String) {
  blog_article(slug: $slug) {
    id
    type
    status
    slug
    publish_at

    data {
      title
      content
      preview

      seo {
        image
        title
        description
        robots
        og_type
      }

      data {
        post_title
        post_subtitle
        pdf_attachment_link
        video_url
        video_preview_url
      }
    }

    categories {
      type
      slug
      id

      data {
        title
      }
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AnalystReportsArticle', 'query'),
    InstallationArticles: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query InstallationArticles(
  $first: Int = 10
  $statuses: [PostStatus!]! = PUBLISHED
) {
  blog_articles(
    types: INSTALLATION,
    first: $first,
    statuses: $statuses
    orderBy: { column: PUBLISH_AT, order: ASC }
  ) {
    data {
      slug
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'InstallationArticles', 'query'),
    InstallationArticle: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query InstallationArticle($slug: String) {
  blog_article(slug: $slug) {
    id
    type
    status
    slug
    publish_at

    data {
      title
      content
      preview

      focus_mode_label
      
      seo {
        image
        title
        description
        robots
        og_type
      }

      content_table {
        href
        title
        nested {
          href
          title
        }
      }

      data {
        languages_nav_title
        content_table_title
        video_title
        video_url
      }
    }

    categories {
      type
      slug
      id

      data {
        title
      }
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'InstallationArticle', 'query'),
    BlogArticleFeatured: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query BlogArticleFeatured {
  blog_articles(featured: true, first: 1, statuses: [PUBLISHED]) {
    data {
      id
      slug
      type
      data {
        title
        data {
          preview_time_to_read
          preview_picture_url
        }
      }
      categories {
        id
        slug
        data {
          title
        }
      }
      authors {
        id
        slug
        data {
          name
          data {
            avatar
          }
        }
      }
      featuredArticleRelationArticles(types: ["POST"], statuses: ["PUBLISHED"]) {
        id
        categories {
          slug
          data {
            title
          }
        }
        data {
          title
          data {
            preview_time_to_read
          }
        }
        publish_at
        slug
      }
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'BlogArticleFeatured', 'query'),
    JobPostsTable: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query JobPostsTable(
  $page: Int = 1
  $first: Int = 10
  $statuses: [String!] = ["PUBLISHED"]
  $locations: [String!]
  $teams: [String!]
  $types: [String!]
) {
  job_posts(
    first: $first,
    statuses: $statuses,
    page: $page,
    locations: $locations,
    teams: $teams,
    types: $types
  ) {
    data {
      slug
      team
      location
      title
      type
    }

    paginatorInfo {
      lastPage
      total
      perPage
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'JobPostsTable', 'query'),
    JobPost: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query JobPost($slug: String, $statuses: [String!] = ["PUBLISHED"]) {
  job_post(slug: $slug, statuses: $statuses) {
    id

    title
    location
    description
    type

    published_at

    seo {
      description
      image
      og_type
      robots
      title
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'JobPost', 'query'),
    Categories: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query Categories($type: PostType = POST) {
  blog_categories(first: 10, type: $type) {
    data {
      id
      
      type
      slug

      data {
        title
        description
      }
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Categories', 'query'),
    Category: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query Category($slug: String) {
  blog_category(slug: $slug) {
    slug

    data {
      title

      seo {
        image
        title
        description
        robots
        og_type
      }
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Category', 'query'),
    GetEvent: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query GetEvent($slug: String) {
  event(slug: $slug) {
    slug
    status
    title
    description
    location
    video
    video_description
    event_date
    created_at

    data {
      video_gate

      links {
        inner
      }
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetEvent', 'query'),
    EventCode: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query EventCode($code: String) {
  event_code(code: $code) {
    _id
    original_id
    code
    lead_source
    is_active
    start_at
    finish_at
    expire_at
    title
    description
    created_at
    updated_at
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'EventCode', 'query'),
    GetEvents: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query GetEvents(
  $first: Int = 6,
  $page: Int,
  $status: EventStatus,
  $type: EventType
  $location: String,
  $date_from: DateTime,
  $date_to: DateTime,
) {
  events(
    first: $first,
    page: $page,
    status: $status,
    statuses: [UPCOMING, ONGOING, ON_DEMAND],
    type: $type,
    location: $location,
    finish_at_from: $date_from,
    date_to: $date_to,
    orderBy: { column: EVENT_DATE, order: ASC }
  ) {
    data {
      _id
      slug
      type
      status
      title
      description
      preview
      location
      event_date
      finish_at

      video

      data {
        video_gate

        links {
          preview
          inner
        }
      }

      created_at
    }

    paginatorInfo {
      lastPage
      total
      perPage
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetEvents', 'query'),
    GetEventsDates: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query GetEventsDates(
  $date_from: DateTime,
  $date_to: DateTime,
) {
  events(
    first: 999999999,

    statuses: [UPCOMING, ONGOING, ON_DEMAND],

    finish_at_from: $date_from,
    date_to: $date_to,
  ) {
    data {
      event_date
      finish_at
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetEventsDates', 'query'),
    RelatedGuides: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query RelatedGuides {
  blog_articles(
    first: 5
    statuses: PUBLISHED
    types: GUIDE
    orderBy: { column: PUBLISH_AT, order: DESC }
  ) {
    data {
      slug
      type

      categories {
        slug
      }

      data {
        title
      }
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RelatedGuides', 'query'),
    MemberCards: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query MemberCards($first: Int = 25) {
  member_cards(first: $first, orderBy: {column: FULL_NAME, order: ASC}) {
    data {
      avatar_url
      bio_content
      country
      country_code
      full_name
      social_links
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MemberCards', 'query'),
    Page: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query Page($slug: String) {
  page(slug: $slug) {
    _id
    original_id
    slug
    hero_content

    seo {
      image
      title
      description
      robots
      og_type
    }
    data {
      webinar_code
    }
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Page', 'query'),
    BreakingNewsBannerSettings: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query BreakingNewsBannerSettings {
  site_setting(key: "breakingNewsBanner") {
    value
  }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'BreakingNewsBannerSettings', 'query')
  }
}