<template>
  <div data-component-name="TheSearch">
    <button class="close" @click="$emit('close')">
      <SvgIcon name="close" />
    </button>

    <TheSearchBar @search="setSearchQuery" />

    <template v-if="Array.isArray(items)">
      <TabList
        v-slot="{ tab, index }"
        :tabs="TABS"
        :active-tab-index
      >
        <Buttons.Regular
          fill="none"
          accent="black"
          @click="changeTab(index)"
        >
          {{ tab.label }}
        </Buttons.Regular>
      </TabList>

      <div v-if="items.length" class="has-results">
        <div class="search-items">
          <TheSearchItem
            v-for="item of items"
            :key="item.id"
            :item
          />
        </div>

        <AppPagination
          v-if="total > query.size"
          v-model="page"
          :total-items="total"
          :items-per-page="query.size"
          :max-pages-shown="4"
        />
      </div>

      <div v-else class="has-no-results">
        <div class="left">
          <p v-html="$t('TheSearch.not-found')" />

          <Buttons.Link
            :to="localePathWithSlash(PATHS.CONTACTS_US.path)"
            @click="$emit('close')"
          >
            {{ $t(PATHS.CONTACTS_US.i18nKey) }}
            <SvgIcon name="arrow-forward" />
          </Buttons.Link>
        </div>

        <div class="right">
          <NuxtImg
            src="/redesign/images/ootbi/ootbi_with_magnifying_glass.webp"
            alt="Object First cartoon character with magnifying glass"

            sizes="xs:0 md:329px xl:430px"
            densities="x1 x2"
            format="webp"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '#i18n';

// components
import TheSearchBar from './Bar.vue';
import TheSearchItem from './Item.vue';
import TabList from '~/components/redesign/TabList.vue';
import Buttons from '~/components/redesign/Buttons';
import AppPagination from '~/components/redesign/AppPagination.vue';

// composables
import useLocalePathWithSlash from '~/composables/useLocalePathWithSlash';

// constants
import { SEARCH } from '~/constants/api-endpoints';
import { PATHS } from '~/constants/nav-items';

// store
import useUIStore from '~/stores/ui';

// types
import type { Search, Queries } from '~/types/search';

// constants
import { PostType } from '~/types/blog-enums';

defineEmits<{ close: [] }>();

const { public: { api_v2: baseURL } } = useRuntimeConfig();

const { t } = useI18n();
const { setLoading } = useUIStore();
const localePathWithSlash = useLocalePathWithSlash();

const query = reactive<Queries>({
  search: '',
  from: 0,
  size: 4,
  type: undefined,
});

const setSearchQuery = (value: string) => {
  query.type = undefined;
  query.search = value;
  activeTabIndex.value = 0;
};

const { data, status } = await useAsyncData(
  'search',
  () => $fetch<Search>(SEARCH, { baseURL, query }),
  {
    immediate: false,
    watch: [...Object.values(toRefs(query))],
  },
);

const items = computed(() => data.value?.data);
const total = computed(() => data.value?.meta.total || 0);
const page = computed({
  get: () => (Math.ceil(query.from / query.size) + 1),
  set: value => {
    query.from = query.size * (value - 1);
  },
});

const TABS = [
  {
    label: t('All'),
    value: undefined,
  },
  {
    label: t('Resources'),
    value: PostType.RESOURCE,
  },
  {
    label: t('Blog'),
    value: PostType.POST,
  },
  {
    label: t('Storage_Guides'),
    value: PostType.GUIDE,
  },
];

const activeTabIndex = ref(0);
const changeTab = (index: number) => {
  activeTabIndex.value = index;
  page.value = 1;
  query.type = TABS[index].value;
};

watchEffect(() => setLoading(status.value === 'pending'));
</script>

<style scoped lang="scss">
@import "$/mixins/flex";
@import "$/mixins/media";
@import "$/mixins/size";
@import "$/mixins/typography";
@import "$/functions/token";
@import "$/variables/shadows";

[data-component-name="TheSearch"] {
  background-color: token('surf-cont-primary');
  box-shadow: map-get($shadows, 'Elevation 1');

  @include flex-stretch-start;
  flex-direction: column;
  padding: 2rem;

  @include tablet {
    padding: 1.5rem;
  }

  @include mobile {
    padding: 1rem;
  }

  button.close {
    @include fixed-size(1.5rem);
    align-self: flex-end;
    margin-bottom: 2rem;

    @include tablet {
      margin-bottom: 1.5rem;
    }

    @include mobile {
      margin-bottom: 1rem;
    }

    svg {
      @include fixed-size(100%)
    }
  }

  [data-component-name="TheSearchBar"] {
    margin-bottom: 1.5rem;
  }

  [data-component-name="TabList"] {
    min-height: 3.9rem;
    margin-bottom: 1.5rem;

    [data-component-name="Buttons.Regular"] {
      @include full-size;
      @include subtitle-3;

      &:deep(span) {
        height: 100%;
      }
    }
  }

  .has-results {
    @include flex-stretch-start;
    flex-direction: column;
    overflow-y: auto;
    gap: 1.5rem 0;

    .search-items {
      @include flex-stretch-start;
      flex-direction: column;
      overflow-y: auto;
      gap: 1.5rem 0;

      [data-component-name="TheSearchItem"] {}
    }

    [data-component-name="AppPagination"] {}
  }

  .has-no-results {
    padding: 3.8rem 0 3.6rem 0;
    @include wrapper(62.75rem);
    @include flex-center-sb;

    @include tablet {
      flex-direction: column;
      padding: 1.3rem 0 0 0;
      gap: 2.6rem 0;
    }

    .left {
      @include flex-start-start;
      flex-direction: column;
      gap: 1.5rem;

      @include tablet {
        gap: 1.4rem;
      }

      p {
        @include body-2;

        @include tablet {
          @include body-3;
          text-align: center;
          line-height: 2rem;
        }
      }

      [data-component-name="Buttons.Link"] {
        @include fluid-max-width(17.5rem);

        @include tablet {
          @include wrapper(13.5rem);
        }

        @include mobile {
          @include wrapper(100%);
        }
      }
    }

    .right {
      img {
        @include mobile {
          display: none;
        }
      }
    }
  }
}
</style>
