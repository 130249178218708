import Activity from '~/analytics/Activity';

export default defineNuxtRouteMiddleware(({ fullPath }) => {
  if (import.meta.server) return;

  const url = window.location.origin + fullPath;

  Activity.dispatch();
  Activity.write(url);

  window.onbeforeunload = () => {
    Activity.dispatch();
  };
});
