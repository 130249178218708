
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93uJ6S9oMrv3Meta } from "/app/pages/3-reasons-ootbi-is-best-for-veeam/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93QhmJPQB188Meta } from "/app/pages/3-reasons-ootbi-is-best-storage-for-veeam/[...slug].vue?macro=true";
import { default as aboutu8Sb32ihwPMeta } from "/app/pages/about.vue?macro=true";
import { default as _91_46_46_46slug_93kLMolKiPv0Meta } from "/app/pages/aces-program/[...slug].vue?macro=true";
import { default as _91slug_93WB3MvnWjfwMeta } from "/app/pages/author/[slug].vue?macro=true";
import { default as _91_46_46_46slug_930yhA4GhtjLMeta } from "/app/pages/backup-storage-calculator/[...slug].vue?macro=true";
import { default as _91slug_93cdf2dv6FbPMeta } from "/app/pages/blog/[slug].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as _91slug_936h8ECWnybOMeta } from "/app/pages/careers/[slug].vue?macro=true";
import { default as indexpW1WXp0mgAMeta } from "/app/pages/careers/index.vue?macro=true";
import { default as channel_45team_45contacts8x8WPAHibKMeta } from "/app/pages/channel-team-contacts.vue?macro=true";
import { default as contactsHSHl3sOssUMeta } from "/app/pages/contacts.vue?macro=true";
import { default as _91_46_46_46slug_93j8Z2o7lRWdMeta } from "/app/pages/demo-ootbi/[...slug].vue?macro=true";
import { default as _91slug_935xGnk5JrpsMeta } from "/app/pages/events/[slug].vue?macro=true";
import { default as indexWgIHVzns1AMeta } from "/app/pages/events/index.vue?macro=true";
import { default as _91_46_46_46slug_931XuX7ez4exMeta } from "/app/pages/gift-campaign/[...slug].vue?macro=true";
import { default as _91slug_937AZio3SPz5Meta } from "/app/pages/guides/[category]/[slug].vue?macro=true";
import { default as data_45backup_45storagedFnAE50rM1Meta } from "/app/pages/guides/data-storage/data-backup-storage.vue?macro=true";
import { default as enterprise_45data_45storage_45for_45backupsWBXsr3mk7WMeta } from "/app/pages/guides/data-storage/enterprise-data-storage-for-backups.vue?macro=true";
import { default as on_45premises_45object_45storageVB4cyrHS2HMeta } from "/app/pages/guides/data-storage/on-premises-object-storage.vue?macro=true";
import { default as immutable_45storagex1pOvfeeF4Meta } from "/app/pages/guides/immutability/immutable-storage.vue?macro=true";
import { default as index2KUukhUoY3Meta } from "/app/pages/guides/index.vue?macro=true";
import { default as _91_46_46_46slug_93BPnjqmIkliMeta } from "/app/pages/help/[...slug].vue?macro=true";
import { default as _91slug_93TOV40XwQYYMeta } from "/app/pages/help/[version]/[slug].vue?macro=true";
import { default as download_45update9obJgI21s2Meta } from "/app/pages/help/download-update.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93x4GW1v6NSXMeta } from "/app/pages/installation/[...slug].vue?macro=true";
import { default as _91slug_93TzhD3BEeTvMeta } from "/app/pages/legal/[slug].vue?macro=true";
import { default as index8NBB3chSIOMeta } from "/app/pages/legal/index.vue?macro=true";
import { default as _91category_93iZet2Yzxp8Meta } from "/app/pages/newsroom/[category].vue?macro=true";
import { default as indexgyJRKObFgMMeta } from "/app/pages/newsroom/index.vue?macro=true";
import { default as indexxUBMmAUGKoMeta } from "/app/pages/newsroom/product-reviews/index.vue?macro=true";
import { default as newsroom9c617uIOskMeta } from "/app/pages/newsroom.vue?macro=true";
import { default as _91_46_46_46slug_93KG2tvwxgIxMeta } from "/app/pages/nis2-directive-white-paper/[...slug].vue?macro=true";
import { default as object_45storage2RYGi0kPuzMeta } from "/app/pages/object-storage.vue?macro=true";
import { default as _91_46_46_46slug_93LcUFkgAq7lMeta } from "/app/pages/ootbi-192tb-gift-campaign/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93cIj3LN2aY2Meta } from "/app/pages/ootbi-best-storage-for-veeam/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93XoawkGWKlmMeta } from "/app/pages/ootbi-welcome-kit/[...slug].vue?macro=true";
import { default as Veeamon_Tour_Mexico_SEP_24ecbUbTqL8WMeta } from "/app/pages/ootbi-welcome-kit/Veeamon_Tour_Mexico_SEP_24.vue?macro=true";
import { default as Veeamon_Tour_Sao_Paulo_AUG_29aDHCfcdqspMeta } from "/app/pages/ootbi-welcome-kit/Veeamon_Tour_Sao_Paulo_AUG_29.vue?macro=true";
import { default as partner_45rewardsT2Adcz84u9Meta } from "/app/pages/partner-rewards.vue?macro=true";
import { default as partnersEg1L314k67Meta } from "/app/pages/partners.vue?macro=true";
import { default as _91_46_46_46code_93Oeml6MnYODMeta } from "/app/pages/recorded-demo/[...code].vue?macro=true";
import { default as request_45live_45demonXSANcgMVgMeta } from "/app/pages/request-live-demo.vue?macro=true";
import { default as _91category_93rhdUO8wqGQMeta } from "/app/pages/resources/[category].vue?macro=true";
import { default as indexapr41HyT6vMeta } from "/app/pages/resources/case-studies/index.vue?macro=true";
import { default as index6TJluURa3EMeta } from "/app/pages/resources/index.vue?macro=true";
import { default as resourceszAaIsuKYxcMeta } from "/app/pages/resources.vue?macro=true";
import { default as see_45you_45soon0C9vOdD16QMeta } from "/app/pages/see-you-soon.vue?macro=true";
import { default as _91_46_46_46slug_936dkBb3VAK3Meta } from "/app/pages/subscription/download-license/[...slug].vue?macro=true";
import { default as supportPKo8CaxFTuMeta } from "/app/pages/support.vue?macro=true";
import { default as _91_46_46_46slug_93a9J48ErsXsMeta } from "/app/pages/trust-center/[...slug].vue?macro=true";
import { default as upcoming_45partner_45webinard4DsYdgFyuMeta } from "/app/pages/upcoming-partner-webinar.vue?macro=true";
import { default as _91_46_46_46slug_93ODBB1XchTcMeta } from "/app/pages/verify-email/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93Wh4YAnZy5SMeta } from "/app/pages/virtual-storage-appliance/[...slug].vue?macro=true";
import { default as setup_45file_45downloadD5FI3VUytXMeta } from "/app/pages/virtual-storage-appliance/setup-file-download.vue?macro=true";
import { default as _91_46_46_46slug_93zCgw7Pfr41Meta } from "/app/pages/zero-trust-data-resilience/[...slug].vue?macro=true";
import { default as indexSGWwDi4qAwMeta } from "/app/pages/newsroom/media-kit/index.vue?macro=true";
import { default as _91slug_93Jt6jHIaURwMeta } from "/app/pages/newsroom/press-releases/[slug].vue?macro=true";
import { default as _91slug_93ZPyHXgvsjLMeta } from "/app/pages/resources/analyst-reports/[slug].vue?macro=true";
import { default as _91slug_93m6SD0DdhgvMeta } from "/app/pages/resources/case-studies/[slug].vue?macro=true";
import { default as data_45sheet_45ootbi_45the_45best_45storage_45for_45veeamYJkOtEYgjAMeta } from "/app/pages/resources/technical-papers/data-sheet-ootbi-the-best-storage-for-veeam.vue?macro=true";
import { default as categorymJSQKyIZpJMeta } from "/app/pages/blog/category.vue?macro=true";
export default [
  {
    name: "3-reasons-ootbi-is-best-for-veeam-slug___en",
    path: "/3-reasons-ootbi-is-best-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93uJ6S9oMrv3Meta || {},
    component: () => import("/app/pages/3-reasons-ootbi-is-best-for-veeam/[...slug].vue")
  },
  {
    name: "3-reasons-ootbi-is-best-for-veeam-slug___de",
    path: "/de/3-reasons-ootbi-is-best-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93uJ6S9oMrv3Meta || {},
    component: () => import("/app/pages/3-reasons-ootbi-is-best-for-veeam/[...slug].vue")
  },
  {
    name: "3-reasons-ootbi-is-best-for-veeam-slug___fr",
    path: "/fr/3-reasons-ootbi-is-best-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93uJ6S9oMrv3Meta || {},
    component: () => import("/app/pages/3-reasons-ootbi-is-best-for-veeam/[...slug].vue")
  },
  {
    name: "3-reasons-ootbi-is-best-for-veeam-slug___es",
    path: "/es/3-reasons-ootbi-is-best-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93uJ6S9oMrv3Meta || {},
    component: () => import("/app/pages/3-reasons-ootbi-is-best-for-veeam/[...slug].vue")
  },
  {
    name: "3-reasons-ootbi-is-best-for-veeam-slug___it",
    path: "/it/3-reasons-ootbi-is-best-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93uJ6S9oMrv3Meta || {},
    component: () => import("/app/pages/3-reasons-ootbi-is-best-for-veeam/[...slug].vue")
  },
  {
    name: "3-reasons-ootbi-is-best-for-veeam-slug___pt",
    path: "/pt/3-reasons-ootbi-is-best-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93uJ6S9oMrv3Meta || {},
    component: () => import("/app/pages/3-reasons-ootbi-is-best-for-veeam/[...slug].vue")
  },
  {
    name: "3-reasons-ootbi-is-best-storage-for-veeam-slug___en",
    path: "/3-reasons-ootbi-is-best-storage-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93QhmJPQB188Meta || {},
    component: () => import("/app/pages/3-reasons-ootbi-is-best-storage-for-veeam/[...slug].vue")
  },
  {
    name: "3-reasons-ootbi-is-best-storage-for-veeam-slug___de",
    path: "/de/3-reasons-ootbi-is-best-storage-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93QhmJPQB188Meta || {},
    component: () => import("/app/pages/3-reasons-ootbi-is-best-storage-for-veeam/[...slug].vue")
  },
  {
    name: "3-reasons-ootbi-is-best-storage-for-veeam-slug___fr",
    path: "/fr/3-reasons-ootbi-is-best-storage-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93QhmJPQB188Meta || {},
    component: () => import("/app/pages/3-reasons-ootbi-is-best-storage-for-veeam/[...slug].vue")
  },
  {
    name: "3-reasons-ootbi-is-best-storage-for-veeam-slug___es",
    path: "/es/3-reasons-ootbi-is-best-storage-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93QhmJPQB188Meta || {},
    component: () => import("/app/pages/3-reasons-ootbi-is-best-storage-for-veeam/[...slug].vue")
  },
  {
    name: "3-reasons-ootbi-is-best-storage-for-veeam-slug___it",
    path: "/it/3-reasons-ootbi-is-best-storage-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93QhmJPQB188Meta || {},
    component: () => import("/app/pages/3-reasons-ootbi-is-best-storage-for-veeam/[...slug].vue")
  },
  {
    name: "3-reasons-ootbi-is-best-storage-for-veeam-slug___pt",
    path: "/pt/3-reasons-ootbi-is-best-storage-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93QhmJPQB188Meta || {},
    component: () => import("/app/pages/3-reasons-ootbi-is-best-storage-for-veeam/[...slug].vue")
  },
  {
    name: "about___en",
    path: "/about",
    component: () => import("/app/pages/about.vue")
  },
  {
    name: "about___de",
    path: "/de/about",
    component: () => import("/app/pages/about.vue")
  },
  {
    name: "about___fr",
    path: "/fr/about",
    component: () => import("/app/pages/about.vue")
  },
  {
    name: "about___es",
    path: "/es/about",
    component: () => import("/app/pages/about.vue")
  },
  {
    name: "about___it",
    path: "/it/about",
    component: () => import("/app/pages/about.vue")
  },
  {
    name: "about___pt",
    path: "/pt/about",
    component: () => import("/app/pages/about.vue")
  },
  {
    name: "aces-program-slug___en",
    path: "/aces-program/:slug(.*)*",
    component: () => import("/app/pages/aces-program/[...slug].vue")
  },
  {
    name: "aces-program-slug___de",
    path: "/de/aces-program/:slug(.*)*",
    component: () => import("/app/pages/aces-program/[...slug].vue")
  },
  {
    name: "aces-program-slug___fr",
    path: "/fr/aces-program/:slug(.*)*",
    component: () => import("/app/pages/aces-program/[...slug].vue")
  },
  {
    name: "aces-program-slug___es",
    path: "/es/aces-program/:slug(.*)*",
    component: () => import("/app/pages/aces-program/[...slug].vue")
  },
  {
    name: "aces-program-slug___it",
    path: "/it/aces-program/:slug(.*)*",
    component: () => import("/app/pages/aces-program/[...slug].vue")
  },
  {
    name: "aces-program-slug___pt",
    path: "/pt/aces-program/:slug(.*)*",
    component: () => import("/app/pages/aces-program/[...slug].vue")
  },
  {
    name: "author-slug___en",
    path: "/author/:slug()",
    component: () => import("/app/pages/author/[slug].vue")
  },
  {
    name: "author-slug___de",
    path: "/de/author/:slug()",
    component: () => import("/app/pages/author/[slug].vue")
  },
  {
    name: "author-slug___fr",
    path: "/fr/author/:slug()",
    component: () => import("/app/pages/author/[slug].vue")
  },
  {
    name: "author-slug___es",
    path: "/es/author/:slug()",
    component: () => import("/app/pages/author/[slug].vue")
  },
  {
    name: "author-slug___it",
    path: "/it/author/:slug()",
    component: () => import("/app/pages/author/[slug].vue")
  },
  {
    name: "author-slug___pt",
    path: "/pt/author/:slug()",
    component: () => import("/app/pages/author/[slug].vue")
  },
  {
    name: "backup-storage-calculator-slug___en",
    path: "/backup-storage-calculator/:slug(.*)*",
    component: () => import("/app/pages/backup-storage-calculator/[...slug].vue")
  },
  {
    name: "backup-storage-calculator-slug___de",
    path: "/de/backup-storage-calculator/:slug(.*)*",
    component: () => import("/app/pages/backup-storage-calculator/[...slug].vue")
  },
  {
    name: "backup-storage-calculator-slug___fr",
    path: "/fr/backup-storage-calculator/:slug(.*)*",
    component: () => import("/app/pages/backup-storage-calculator/[...slug].vue")
  },
  {
    name: "backup-storage-calculator-slug___es",
    path: "/es/backup-storage-calculator/:slug(.*)*",
    component: () => import("/app/pages/backup-storage-calculator/[...slug].vue")
  },
  {
    name: "backup-storage-calculator-slug___it",
    path: "/it/backup-storage-calculator/:slug(.*)*",
    component: () => import("/app/pages/backup-storage-calculator/[...slug].vue")
  },
  {
    name: "backup-storage-calculator-slug___pt",
    path: "/pt/backup-storage-calculator/:slug(.*)*",
    component: () => import("/app/pages/backup-storage-calculator/[...slug].vue")
  },
  {
    name: "blog-slug___en",
    path: "/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___de",
    path: "/de/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___fr",
    path: "/fr/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___es",
    path: "/es/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___it",
    path: "/it/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___pt",
    path: "/pt/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue")
  },
  {
    name: "blog___en",
    path: "/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___de",
    path: "/de/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___fr",
    path: "/fr/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___es",
    path: "/es/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___it",
    path: "/it/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___pt",
    path: "/pt/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "careers-slug___en",
    path: "/careers/:slug()",
    component: () => import("/app/pages/careers/[slug].vue")
  },
  {
    name: "careers-slug___de",
    path: "/de/careers/:slug()",
    component: () => import("/app/pages/careers/[slug].vue")
  },
  {
    name: "careers-slug___fr",
    path: "/fr/careers/:slug()",
    component: () => import("/app/pages/careers/[slug].vue")
  },
  {
    name: "careers-slug___es",
    path: "/es/careers/:slug()",
    component: () => import("/app/pages/careers/[slug].vue")
  },
  {
    name: "careers-slug___it",
    path: "/it/careers/:slug()",
    component: () => import("/app/pages/careers/[slug].vue")
  },
  {
    name: "careers-slug___pt",
    path: "/pt/careers/:slug()",
    component: () => import("/app/pages/careers/[slug].vue")
  },
  {
    name: "careers___en",
    path: "/careers",
    component: () => import("/app/pages/careers/index.vue")
  },
  {
    name: "careers___de",
    path: "/de/careers",
    component: () => import("/app/pages/careers/index.vue")
  },
  {
    name: "careers___fr",
    path: "/fr/careers",
    component: () => import("/app/pages/careers/index.vue")
  },
  {
    name: "careers___es",
    path: "/es/careers",
    component: () => import("/app/pages/careers/index.vue")
  },
  {
    name: "careers___it",
    path: "/it/careers",
    component: () => import("/app/pages/careers/index.vue")
  },
  {
    name: "careers___pt",
    path: "/pt/careers",
    component: () => import("/app/pages/careers/index.vue")
  },
  {
    name: "channel-team-contacts___en",
    path: "/channel-team-contacts",
    component: () => import("/app/pages/channel-team-contacts.vue")
  },
  {
    name: "channel-team-contacts___de",
    path: "/de/channel-team-contacts",
    component: () => import("/app/pages/channel-team-contacts.vue")
  },
  {
    name: "channel-team-contacts___fr",
    path: "/fr/channel-team-contacts",
    component: () => import("/app/pages/channel-team-contacts.vue")
  },
  {
    name: "channel-team-contacts___es",
    path: "/es/channel-team-contacts",
    component: () => import("/app/pages/channel-team-contacts.vue")
  },
  {
    name: "channel-team-contacts___it",
    path: "/it/channel-team-contacts",
    component: () => import("/app/pages/channel-team-contacts.vue")
  },
  {
    name: "channel-team-contacts___pt",
    path: "/pt/channel-team-contacts",
    component: () => import("/app/pages/channel-team-contacts.vue")
  },
  {
    name: "contacts___en",
    path: "/contacts",
    component: () => import("/app/pages/contacts.vue")
  },
  {
    name: "contacts___de",
    path: "/de/contacts",
    component: () => import("/app/pages/contacts.vue")
  },
  {
    name: "contacts___fr",
    path: "/fr/contacts",
    component: () => import("/app/pages/contacts.vue")
  },
  {
    name: "contacts___es",
    path: "/es/contacts",
    component: () => import("/app/pages/contacts.vue")
  },
  {
    name: "contacts___it",
    path: "/it/contacts",
    component: () => import("/app/pages/contacts.vue")
  },
  {
    name: "contacts___pt",
    path: "/pt/contacts",
    component: () => import("/app/pages/contacts.vue")
  },
  {
    name: "demo-ootbi-slug___en",
    path: "/demo-ootbi/:slug(.*)*",
    meta: _91_46_46_46slug_93j8Z2o7lRWdMeta || {},
    component: () => import("/app/pages/demo-ootbi/[...slug].vue")
  },
  {
    name: "demo-ootbi-slug___de",
    path: "/de/demo-ootbi/:slug(.*)*",
    meta: _91_46_46_46slug_93j8Z2o7lRWdMeta || {},
    component: () => import("/app/pages/demo-ootbi/[...slug].vue")
  },
  {
    name: "demo-ootbi-slug___fr",
    path: "/fr/demo-ootbi/:slug(.*)*",
    meta: _91_46_46_46slug_93j8Z2o7lRWdMeta || {},
    component: () => import("/app/pages/demo-ootbi/[...slug].vue")
  },
  {
    name: "demo-ootbi-slug___es",
    path: "/es/demo-ootbi/:slug(.*)*",
    meta: _91_46_46_46slug_93j8Z2o7lRWdMeta || {},
    component: () => import("/app/pages/demo-ootbi/[...slug].vue")
  },
  {
    name: "demo-ootbi-slug___it",
    path: "/it/demo-ootbi/:slug(.*)*",
    meta: _91_46_46_46slug_93j8Z2o7lRWdMeta || {},
    component: () => import("/app/pages/demo-ootbi/[...slug].vue")
  },
  {
    name: "demo-ootbi-slug___pt",
    path: "/pt/demo-ootbi/:slug(.*)*",
    meta: _91_46_46_46slug_93j8Z2o7lRWdMeta || {},
    component: () => import("/app/pages/demo-ootbi/[...slug].vue")
  },
  {
    name: "events-slug___en",
    path: "/events/:slug()",
    component: () => import("/app/pages/events/[slug].vue")
  },
  {
    name: "events-slug___de",
    path: "/de/events/:slug()",
    component: () => import("/app/pages/events/[slug].vue")
  },
  {
    name: "events-slug___fr",
    path: "/fr/events/:slug()",
    component: () => import("/app/pages/events/[slug].vue")
  },
  {
    name: "events-slug___es",
    path: "/es/events/:slug()",
    component: () => import("/app/pages/events/[slug].vue")
  },
  {
    name: "events-slug___it",
    path: "/it/events/:slug()",
    component: () => import("/app/pages/events/[slug].vue")
  },
  {
    name: "events-slug___pt",
    path: "/pt/events/:slug()",
    component: () => import("/app/pages/events/[slug].vue")
  },
  {
    name: "events___en",
    path: "/events",
    component: () => import("/app/pages/events/index.vue")
  },
  {
    name: "events___de",
    path: "/de/events",
    component: () => import("/app/pages/events/index.vue")
  },
  {
    name: "events___fr",
    path: "/fr/events",
    component: () => import("/app/pages/events/index.vue")
  },
  {
    name: "events___es",
    path: "/es/events",
    component: () => import("/app/pages/events/index.vue")
  },
  {
    name: "events___it",
    path: "/it/events",
    component: () => import("/app/pages/events/index.vue")
  },
  {
    name: "events___pt",
    path: "/pt/events",
    component: () => import("/app/pages/events/index.vue")
  },
  {
    name: "gift-campaign-slug___en",
    path: "/gift-campaign/:slug(.*)*",
    meta: _91_46_46_46slug_931XuX7ez4exMeta || {},
    component: () => import("/app/pages/gift-campaign/[...slug].vue")
  },
  {
    name: "gift-campaign-slug___de",
    path: "/de/gift-campaign/:slug(.*)*",
    meta: _91_46_46_46slug_931XuX7ez4exMeta || {},
    component: () => import("/app/pages/gift-campaign/[...slug].vue")
  },
  {
    name: "gift-campaign-slug___fr",
    path: "/fr/gift-campaign/:slug(.*)*",
    meta: _91_46_46_46slug_931XuX7ez4exMeta || {},
    component: () => import("/app/pages/gift-campaign/[...slug].vue")
  },
  {
    name: "gift-campaign-slug___es",
    path: "/es/gift-campaign/:slug(.*)*",
    meta: _91_46_46_46slug_931XuX7ez4exMeta || {},
    component: () => import("/app/pages/gift-campaign/[...slug].vue")
  },
  {
    name: "gift-campaign-slug___it",
    path: "/it/gift-campaign/:slug(.*)*",
    meta: _91_46_46_46slug_931XuX7ez4exMeta || {},
    component: () => import("/app/pages/gift-campaign/[...slug].vue")
  },
  {
    name: "gift-campaign-slug___pt",
    path: "/pt/gift-campaign/:slug(.*)*",
    meta: _91_46_46_46slug_931XuX7ez4exMeta || {},
    component: () => import("/app/pages/gift-campaign/[...slug].vue")
  },
  {
    name: "guides-category-slug___en",
    path: "/guides/:category()/:slug()",
    component: () => import("/app/pages/guides/[category]/[slug].vue")
  },
  {
    name: "guides-category-slug___de",
    path: "/de/guides/:category()/:slug()",
    component: () => import("/app/pages/guides/[category]/[slug].vue")
  },
  {
    name: "guides-category-slug___fr",
    path: "/fr/guides/:category()/:slug()",
    component: () => import("/app/pages/guides/[category]/[slug].vue")
  },
  {
    name: "guides-category-slug___es",
    path: "/es/guides/:category()/:slug()",
    component: () => import("/app/pages/guides/[category]/[slug].vue")
  },
  {
    name: "guides-category-slug___it",
    path: "/it/guides/:category()/:slug()",
    component: () => import("/app/pages/guides/[category]/[slug].vue")
  },
  {
    name: "guides-category-slug___pt",
    path: "/pt/guides/:category()/:slug()",
    component: () => import("/app/pages/guides/[category]/[slug].vue")
  },
  {
    name: "guides-data-storage-data-backup-storage___en",
    path: "/guides/data-storage/data-backup-storage",
    component: () => import("/app/pages/guides/data-storage/data-backup-storage.vue")
  },
  {
    name: "guides-data-storage-data-backup-storage___de",
    path: "/de/guides/data-storage/data-backup-storage",
    component: () => import("/app/pages/guides/data-storage/data-backup-storage.vue")
  },
  {
    name: "guides-data-storage-data-backup-storage___fr",
    path: "/fr/guides/data-storage/data-backup-storage",
    component: () => import("/app/pages/guides/data-storage/data-backup-storage.vue")
  },
  {
    name: "guides-data-storage-data-backup-storage___es",
    path: "/es/guides/data-storage/data-backup-storage",
    component: () => import("/app/pages/guides/data-storage/data-backup-storage.vue")
  },
  {
    name: "guides-data-storage-data-backup-storage___it",
    path: "/it/guides/data-storage/data-backup-storage",
    component: () => import("/app/pages/guides/data-storage/data-backup-storage.vue")
  },
  {
    name: "guides-data-storage-data-backup-storage___pt",
    path: "/pt/guides/data-storage/data-backup-storage",
    component: () => import("/app/pages/guides/data-storage/data-backup-storage.vue")
  },
  {
    name: "guides-data-storage-enterprise-data-storage-for-backups___en",
    path: "/guides/data-storage/enterprise-data-storage-for-backups",
    component: () => import("/app/pages/guides/data-storage/enterprise-data-storage-for-backups.vue")
  },
  {
    name: "guides-data-storage-enterprise-data-storage-for-backups___de",
    path: "/de/guides/data-storage/enterprise-data-storage-for-backups",
    component: () => import("/app/pages/guides/data-storage/enterprise-data-storage-for-backups.vue")
  },
  {
    name: "guides-data-storage-enterprise-data-storage-for-backups___fr",
    path: "/fr/guides/data-storage/enterprise-data-storage-for-backups",
    component: () => import("/app/pages/guides/data-storage/enterprise-data-storage-for-backups.vue")
  },
  {
    name: "guides-data-storage-enterprise-data-storage-for-backups___es",
    path: "/es/guides/data-storage/enterprise-data-storage-for-backups",
    component: () => import("/app/pages/guides/data-storage/enterprise-data-storage-for-backups.vue")
  },
  {
    name: "guides-data-storage-enterprise-data-storage-for-backups___it",
    path: "/it/guides/data-storage/enterprise-data-storage-for-backups",
    component: () => import("/app/pages/guides/data-storage/enterprise-data-storage-for-backups.vue")
  },
  {
    name: "guides-data-storage-enterprise-data-storage-for-backups___pt",
    path: "/pt/guides/data-storage/enterprise-data-storage-for-backups",
    component: () => import("/app/pages/guides/data-storage/enterprise-data-storage-for-backups.vue")
  },
  {
    name: "guides-data-storage-on-premises-object-storage___en",
    path: "/guides/data-storage/on-premises-object-storage",
    component: () => import("/app/pages/guides/data-storage/on-premises-object-storage.vue")
  },
  {
    name: "guides-data-storage-on-premises-object-storage___de",
    path: "/de/guides/data-storage/on-premises-object-storage",
    component: () => import("/app/pages/guides/data-storage/on-premises-object-storage.vue")
  },
  {
    name: "guides-data-storage-on-premises-object-storage___fr",
    path: "/fr/guides/data-storage/on-premises-object-storage",
    component: () => import("/app/pages/guides/data-storage/on-premises-object-storage.vue")
  },
  {
    name: "guides-data-storage-on-premises-object-storage___es",
    path: "/es/guides/data-storage/on-premises-object-storage",
    component: () => import("/app/pages/guides/data-storage/on-premises-object-storage.vue")
  },
  {
    name: "guides-data-storage-on-premises-object-storage___it",
    path: "/it/guides/data-storage/on-premises-object-storage",
    component: () => import("/app/pages/guides/data-storage/on-premises-object-storage.vue")
  },
  {
    name: "guides-data-storage-on-premises-object-storage___pt",
    path: "/pt/guides/data-storage/on-premises-object-storage",
    component: () => import("/app/pages/guides/data-storage/on-premises-object-storage.vue")
  },
  {
    name: "guides-immutability-immutable-storage___en",
    path: "/guides/immutability/immutable-storage",
    component: () => import("/app/pages/guides/immutability/immutable-storage.vue")
  },
  {
    name: "guides-immutability-immutable-storage___de",
    path: "/de/guides/immutability/immutable-storage",
    component: () => import("/app/pages/guides/immutability/immutable-storage.vue")
  },
  {
    name: "guides-immutability-immutable-storage___fr",
    path: "/fr/guides/immutability/immutable-storage",
    component: () => import("/app/pages/guides/immutability/immutable-storage.vue")
  },
  {
    name: "guides-immutability-immutable-storage___es",
    path: "/es/guides/immutability/immutable-storage",
    component: () => import("/app/pages/guides/immutability/immutable-storage.vue")
  },
  {
    name: "guides-immutability-immutable-storage___it",
    path: "/it/guides/immutability/immutable-storage",
    component: () => import("/app/pages/guides/immutability/immutable-storage.vue")
  },
  {
    name: "guides-immutability-immutable-storage___pt",
    path: "/pt/guides/immutability/immutable-storage",
    component: () => import("/app/pages/guides/immutability/immutable-storage.vue")
  },
  {
    name: "guides___en",
    path: "/guides",
    component: () => import("/app/pages/guides/index.vue")
  },
  {
    name: "guides___de",
    path: "/de/guides",
    component: () => import("/app/pages/guides/index.vue")
  },
  {
    name: "guides___fr",
    path: "/fr/guides",
    component: () => import("/app/pages/guides/index.vue")
  },
  {
    name: "guides___es",
    path: "/es/guides",
    component: () => import("/app/pages/guides/index.vue")
  },
  {
    name: "guides___it",
    path: "/it/guides",
    component: () => import("/app/pages/guides/index.vue")
  },
  {
    name: "guides___pt",
    path: "/pt/guides",
    component: () => import("/app/pages/guides/index.vue")
  },
  {
    name: "help-slug___en",
    path: "/help/:slug(.*)*",
    meta: _91_46_46_46slug_93BPnjqmIkliMeta || {},
    component: () => import("/app/pages/help/[...slug].vue")
  },
  {
    name: "help-slug___de",
    path: "/de/help/:slug(.*)*",
    meta: _91_46_46_46slug_93BPnjqmIkliMeta || {},
    component: () => import("/app/pages/help/[...slug].vue")
  },
  {
    name: "help-slug___fr",
    path: "/fr/help/:slug(.*)*",
    meta: _91_46_46_46slug_93BPnjqmIkliMeta || {},
    component: () => import("/app/pages/help/[...slug].vue")
  },
  {
    name: "help-slug___es",
    path: "/es/help/:slug(.*)*",
    meta: _91_46_46_46slug_93BPnjqmIkliMeta || {},
    component: () => import("/app/pages/help/[...slug].vue")
  },
  {
    name: "help-slug___it",
    path: "/it/help/:slug(.*)*",
    meta: _91_46_46_46slug_93BPnjqmIkliMeta || {},
    component: () => import("/app/pages/help/[...slug].vue")
  },
  {
    name: "help-slug___pt",
    path: "/pt/help/:slug(.*)*",
    meta: _91_46_46_46slug_93BPnjqmIkliMeta || {},
    component: () => import("/app/pages/help/[...slug].vue")
  },
  {
    name: "help-version-slug___en",
    path: "/help/:version()/:slug()",
    meta: _91slug_93TOV40XwQYYMeta || {},
    component: () => import("/app/pages/help/[version]/[slug].vue")
  },
  {
    name: "help-version-slug___de",
    path: "/de/help/:version()/:slug()",
    meta: _91slug_93TOV40XwQYYMeta || {},
    component: () => import("/app/pages/help/[version]/[slug].vue")
  },
  {
    name: "help-version-slug___fr",
    path: "/fr/help/:version()/:slug()",
    meta: _91slug_93TOV40XwQYYMeta || {},
    component: () => import("/app/pages/help/[version]/[slug].vue")
  },
  {
    name: "help-version-slug___es",
    path: "/es/help/:version()/:slug()",
    meta: _91slug_93TOV40XwQYYMeta || {},
    component: () => import("/app/pages/help/[version]/[slug].vue")
  },
  {
    name: "help-version-slug___it",
    path: "/it/help/:version()/:slug()",
    meta: _91slug_93TOV40XwQYYMeta || {},
    component: () => import("/app/pages/help/[version]/[slug].vue")
  },
  {
    name: "help-version-slug___pt",
    path: "/pt/help/:version()/:slug()",
    meta: _91slug_93TOV40XwQYYMeta || {},
    component: () => import("/app/pages/help/[version]/[slug].vue")
  },
  {
    name: "help-download-update___en",
    path: "/help/download-update",
    meta: download_45update9obJgI21s2Meta || {},
    component: () => import("/app/pages/help/download-update.vue")
  },
  {
    name: "help-download-update___de",
    path: "/de/help/download-update",
    meta: download_45update9obJgI21s2Meta || {},
    component: () => import("/app/pages/help/download-update.vue")
  },
  {
    name: "help-download-update___fr",
    path: "/fr/help/download-update",
    meta: download_45update9obJgI21s2Meta || {},
    component: () => import("/app/pages/help/download-update.vue")
  },
  {
    name: "help-download-update___es",
    path: "/es/help/download-update",
    meta: download_45update9obJgI21s2Meta || {},
    component: () => import("/app/pages/help/download-update.vue")
  },
  {
    name: "help-download-update___it",
    path: "/it/help/download-update",
    meta: download_45update9obJgI21s2Meta || {},
    component: () => import("/app/pages/help/download-update.vue")
  },
  {
    name: "help-download-update___pt",
    path: "/pt/help/download-update",
    meta: download_45update9obJgI21s2Meta || {},
    component: () => import("/app/pages/help/download-update.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___pt",
    path: "/pt",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "installation-slug___en",
    path: "/installation/:slug(.*)*",
    component: () => import("/app/pages/installation/[...slug].vue")
  },
  {
    name: "installation-slug___de",
    path: "/de/installation/:slug(.*)*",
    component: () => import("/app/pages/installation/[...slug].vue")
  },
  {
    name: "installation-slug___fr",
    path: "/fr/installation/:slug(.*)*",
    component: () => import("/app/pages/installation/[...slug].vue")
  },
  {
    name: "installation-slug___es",
    path: "/es/installation/:slug(.*)*",
    component: () => import("/app/pages/installation/[...slug].vue")
  },
  {
    name: "installation-slug___it",
    path: "/it/installation/:slug(.*)*",
    component: () => import("/app/pages/installation/[...slug].vue")
  },
  {
    name: "installation-slug___pt",
    path: "/pt/installation/:slug(.*)*",
    component: () => import("/app/pages/installation/[...slug].vue")
  },
  {
    name: "legal-slug___en",
    path: "/legal/:slug()",
    component: () => import("/app/pages/legal/[slug].vue")
  },
  {
    name: "legal-slug___de",
    path: "/de/legal/:slug()",
    component: () => import("/app/pages/legal/[slug].vue")
  },
  {
    name: "legal-slug___fr",
    path: "/fr/legal/:slug()",
    component: () => import("/app/pages/legal/[slug].vue")
  },
  {
    name: "legal-slug___es",
    path: "/es/legal/:slug()",
    component: () => import("/app/pages/legal/[slug].vue")
  },
  {
    name: "legal-slug___it",
    path: "/it/legal/:slug()",
    component: () => import("/app/pages/legal/[slug].vue")
  },
  {
    name: "legal-slug___pt",
    path: "/pt/legal/:slug()",
    component: () => import("/app/pages/legal/[slug].vue")
  },
  {
    name: "legal___en",
    path: "/legal",
    component: () => import("/app/pages/legal/index.vue")
  },
  {
    name: "legal___de",
    path: "/de/legal",
    component: () => import("/app/pages/legal/index.vue")
  },
  {
    name: "legal___fr",
    path: "/fr/legal",
    component: () => import("/app/pages/legal/index.vue")
  },
  {
    name: "legal___es",
    path: "/es/legal",
    component: () => import("/app/pages/legal/index.vue")
  },
  {
    name: "legal___it",
    path: "/it/legal",
    component: () => import("/app/pages/legal/index.vue")
  },
  {
    name: "legal___pt",
    path: "/pt/legal",
    component: () => import("/app/pages/legal/index.vue")
  },
  {
    name: newsroom9c617uIOskMeta?.name,
    path: "/newsroom",
    component: () => import("/app/pages/newsroom.vue"),
    children: [
  {
    name: "newsroom-category___en",
    path: ":category()",
    component: () => import("/app/pages/newsroom/[category].vue")
  },
  {
    name: "newsroom___en",
    path: "",
    component: () => import("/app/pages/newsroom/index.vue")
  },
  {
    name: "newsroom-product-reviews___en",
    path: "product-reviews",
    component: () => import("/app/pages/newsroom/product-reviews/index.vue")
  }
]
  },
  {
    name: newsroom9c617uIOskMeta?.name,
    path: "/de/newsroom",
    component: () => import("/app/pages/newsroom.vue"),
    children: [
  {
    name: "newsroom-category___de",
    path: ":category()",
    component: () => import("/app/pages/newsroom/[category].vue")
  },
  {
    name: "newsroom___de",
    path: "",
    component: () => import("/app/pages/newsroom/index.vue")
  },
  {
    name: "newsroom-product-reviews___de",
    path: "product-reviews",
    component: () => import("/app/pages/newsroom/product-reviews/index.vue")
  }
]
  },
  {
    name: newsroom9c617uIOskMeta?.name,
    path: "/fr/newsroom",
    component: () => import("/app/pages/newsroom.vue"),
    children: [
  {
    name: "newsroom-category___fr",
    path: ":category()",
    component: () => import("/app/pages/newsroom/[category].vue")
  },
  {
    name: "newsroom___fr",
    path: "",
    component: () => import("/app/pages/newsroom/index.vue")
  },
  {
    name: "newsroom-product-reviews___fr",
    path: "product-reviews",
    component: () => import("/app/pages/newsroom/product-reviews/index.vue")
  }
]
  },
  {
    name: newsroom9c617uIOskMeta?.name,
    path: "/es/newsroom",
    component: () => import("/app/pages/newsroom.vue"),
    children: [
  {
    name: "newsroom-category___es",
    path: ":category()",
    component: () => import("/app/pages/newsroom/[category].vue")
  },
  {
    name: "newsroom___es",
    path: "",
    component: () => import("/app/pages/newsroom/index.vue")
  },
  {
    name: "newsroom-product-reviews___es",
    path: "product-reviews",
    component: () => import("/app/pages/newsroom/product-reviews/index.vue")
  }
]
  },
  {
    name: newsroom9c617uIOskMeta?.name,
    path: "/it/newsroom",
    component: () => import("/app/pages/newsroom.vue"),
    children: [
  {
    name: "newsroom-category___it",
    path: ":category()",
    component: () => import("/app/pages/newsroom/[category].vue")
  },
  {
    name: "newsroom___it",
    path: "",
    component: () => import("/app/pages/newsroom/index.vue")
  },
  {
    name: "newsroom-product-reviews___it",
    path: "product-reviews",
    component: () => import("/app/pages/newsroom/product-reviews/index.vue")
  }
]
  },
  {
    name: newsroom9c617uIOskMeta?.name,
    path: "/pt/newsroom",
    component: () => import("/app/pages/newsroom.vue"),
    children: [
  {
    name: "newsroom-category___pt",
    path: ":category()",
    component: () => import("/app/pages/newsroom/[category].vue")
  },
  {
    name: "newsroom___pt",
    path: "",
    component: () => import("/app/pages/newsroom/index.vue")
  },
  {
    name: "newsroom-product-reviews___pt",
    path: "product-reviews",
    component: () => import("/app/pages/newsroom/product-reviews/index.vue")
  }
]
  },
  {
    name: "nis2-directive-white-paper-slug___en",
    path: "/nis2-directive-white-paper/:slug(.*)*",
    meta: _91_46_46_46slug_93KG2tvwxgIxMeta || {},
    component: () => import("/app/pages/nis2-directive-white-paper/[...slug].vue")
  },
  {
    name: "nis2-directive-white-paper-slug___de",
    path: "/de/nis2-directive-white-paper/:slug(.*)*",
    meta: _91_46_46_46slug_93KG2tvwxgIxMeta || {},
    component: () => import("/app/pages/nis2-directive-white-paper/[...slug].vue")
  },
  {
    name: "nis2-directive-white-paper-slug___fr",
    path: "/fr/nis2-directive-white-paper/:slug(.*)*",
    meta: _91_46_46_46slug_93KG2tvwxgIxMeta || {},
    component: () => import("/app/pages/nis2-directive-white-paper/[...slug].vue")
  },
  {
    name: "nis2-directive-white-paper-slug___es",
    path: "/es/nis2-directive-white-paper/:slug(.*)*",
    meta: _91_46_46_46slug_93KG2tvwxgIxMeta || {},
    component: () => import("/app/pages/nis2-directive-white-paper/[...slug].vue")
  },
  {
    name: "nis2-directive-white-paper-slug___it",
    path: "/it/nis2-directive-white-paper/:slug(.*)*",
    meta: _91_46_46_46slug_93KG2tvwxgIxMeta || {},
    component: () => import("/app/pages/nis2-directive-white-paper/[...slug].vue")
  },
  {
    name: "nis2-directive-white-paper-slug___pt",
    path: "/pt/nis2-directive-white-paper/:slug(.*)*",
    meta: _91_46_46_46slug_93KG2tvwxgIxMeta || {},
    component: () => import("/app/pages/nis2-directive-white-paper/[...slug].vue")
  },
  {
    name: "object-storage___en",
    path: "/object-storage",
    component: () => import("/app/pages/object-storage.vue")
  },
  {
    name: "object-storage___de",
    path: "/de/object-storage",
    component: () => import("/app/pages/object-storage.vue")
  },
  {
    name: "object-storage___fr",
    path: "/fr/object-storage",
    component: () => import("/app/pages/object-storage.vue")
  },
  {
    name: "object-storage___es",
    path: "/es/object-storage",
    component: () => import("/app/pages/object-storage.vue")
  },
  {
    name: "object-storage___it",
    path: "/it/object-storage",
    component: () => import("/app/pages/object-storage.vue")
  },
  {
    name: "object-storage___pt",
    path: "/pt/object-storage",
    component: () => import("/app/pages/object-storage.vue")
  },
  {
    name: "ootbi-192tb-gift-campaign-slug___en",
    path: "/ootbi-192tb-gift-campaign/:slug(.*)*",
    meta: _91_46_46_46slug_93LcUFkgAq7lMeta || {},
    component: () => import("/app/pages/ootbi-192tb-gift-campaign/[...slug].vue")
  },
  {
    name: "ootbi-192tb-gift-campaign-slug___de",
    path: "/de/ootbi-192tb-gift-campaign/:slug(.*)*",
    meta: _91_46_46_46slug_93LcUFkgAq7lMeta || {},
    component: () => import("/app/pages/ootbi-192tb-gift-campaign/[...slug].vue")
  },
  {
    name: "ootbi-192tb-gift-campaign-slug___fr",
    path: "/fr/ootbi-192tb-gift-campaign/:slug(.*)*",
    meta: _91_46_46_46slug_93LcUFkgAq7lMeta || {},
    component: () => import("/app/pages/ootbi-192tb-gift-campaign/[...slug].vue")
  },
  {
    name: "ootbi-192tb-gift-campaign-slug___es",
    path: "/es/ootbi-192tb-gift-campaign/:slug(.*)*",
    meta: _91_46_46_46slug_93LcUFkgAq7lMeta || {},
    component: () => import("/app/pages/ootbi-192tb-gift-campaign/[...slug].vue")
  },
  {
    name: "ootbi-192tb-gift-campaign-slug___it",
    path: "/it/ootbi-192tb-gift-campaign/:slug(.*)*",
    meta: _91_46_46_46slug_93LcUFkgAq7lMeta || {},
    component: () => import("/app/pages/ootbi-192tb-gift-campaign/[...slug].vue")
  },
  {
    name: "ootbi-192tb-gift-campaign-slug___pt",
    path: "/pt/ootbi-192tb-gift-campaign/:slug(.*)*",
    meta: _91_46_46_46slug_93LcUFkgAq7lMeta || {},
    component: () => import("/app/pages/ootbi-192tb-gift-campaign/[...slug].vue")
  },
  {
    name: "ootbi-best-storage-for-veeam-slug___en",
    path: "/ootbi-best-storage-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93cIj3LN2aY2Meta || {},
    component: () => import("/app/pages/ootbi-best-storage-for-veeam/[...slug].vue")
  },
  {
    name: "ootbi-best-storage-for-veeam-slug___de",
    path: "/de/ootbi-best-storage-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93cIj3LN2aY2Meta || {},
    component: () => import("/app/pages/ootbi-best-storage-for-veeam/[...slug].vue")
  },
  {
    name: "ootbi-best-storage-for-veeam-slug___fr",
    path: "/fr/ootbi-best-storage-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93cIj3LN2aY2Meta || {},
    component: () => import("/app/pages/ootbi-best-storage-for-veeam/[...slug].vue")
  },
  {
    name: "ootbi-best-storage-for-veeam-slug___es",
    path: "/es/ootbi-best-storage-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93cIj3LN2aY2Meta || {},
    component: () => import("/app/pages/ootbi-best-storage-for-veeam/[...slug].vue")
  },
  {
    name: "ootbi-best-storage-for-veeam-slug___it",
    path: "/it/ootbi-best-storage-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93cIj3LN2aY2Meta || {},
    component: () => import("/app/pages/ootbi-best-storage-for-veeam/[...slug].vue")
  },
  {
    name: "ootbi-best-storage-for-veeam-slug___pt",
    path: "/pt/ootbi-best-storage-for-veeam/:slug(.*)*",
    meta: _91_46_46_46slug_93cIj3LN2aY2Meta || {},
    component: () => import("/app/pages/ootbi-best-storage-for-veeam/[...slug].vue")
  },
  {
    name: "ootbi-welcome-kit-slug___en",
    path: "/ootbi-welcome-kit/:slug(.*)*",
    meta: _91_46_46_46slug_93XoawkGWKlmMeta || {},
    component: () => import("/app/pages/ootbi-welcome-kit/[...slug].vue")
  },
  {
    name: "ootbi-welcome-kit-slug___de",
    path: "/de/ootbi-welcome-kit/:slug(.*)*",
    meta: _91_46_46_46slug_93XoawkGWKlmMeta || {},
    component: () => import("/app/pages/ootbi-welcome-kit/[...slug].vue")
  },
  {
    name: "ootbi-welcome-kit-slug___fr",
    path: "/fr/ootbi-welcome-kit/:slug(.*)*",
    meta: _91_46_46_46slug_93XoawkGWKlmMeta || {},
    component: () => import("/app/pages/ootbi-welcome-kit/[...slug].vue")
  },
  {
    name: "ootbi-welcome-kit-slug___es",
    path: "/es/ootbi-welcome-kit/:slug(.*)*",
    meta: _91_46_46_46slug_93XoawkGWKlmMeta || {},
    component: () => import("/app/pages/ootbi-welcome-kit/[...slug].vue")
  },
  {
    name: "ootbi-welcome-kit-slug___it",
    path: "/it/ootbi-welcome-kit/:slug(.*)*",
    meta: _91_46_46_46slug_93XoawkGWKlmMeta || {},
    component: () => import("/app/pages/ootbi-welcome-kit/[...slug].vue")
  },
  {
    name: "ootbi-welcome-kit-slug___pt",
    path: "/pt/ootbi-welcome-kit/:slug(.*)*",
    meta: _91_46_46_46slug_93XoawkGWKlmMeta || {},
    component: () => import("/app/pages/ootbi-welcome-kit/[...slug].vue")
  },
  {
    name: "ootbi-welcome-kit-Veeamon_Tour_Mexico_SEP_24___en",
    path: "/ootbi-welcome-kit/Veeamon_Tour_Mexico_SEP_24",
    meta: Veeamon_Tour_Mexico_SEP_24ecbUbTqL8WMeta || {},
    component: () => import("/app/pages/ootbi-welcome-kit/Veeamon_Tour_Mexico_SEP_24.vue")
  },
  {
    name: "ootbi-welcome-kit-Veeamon_Tour_Mexico_SEP_24___de",
    path: "/de/ootbi-welcome-kit/Veeamon_Tour_Mexico_SEP_24",
    meta: Veeamon_Tour_Mexico_SEP_24ecbUbTqL8WMeta || {},
    component: () => import("/app/pages/ootbi-welcome-kit/Veeamon_Tour_Mexico_SEP_24.vue")
  },
  {
    name: "ootbi-welcome-kit-Veeamon_Tour_Mexico_SEP_24___fr",
    path: "/fr/ootbi-welcome-kit/Veeamon_Tour_Mexico_SEP_24",
    meta: Veeamon_Tour_Mexico_SEP_24ecbUbTqL8WMeta || {},
    component: () => import("/app/pages/ootbi-welcome-kit/Veeamon_Tour_Mexico_SEP_24.vue")
  },
  {
    name: "ootbi-welcome-kit-Veeamon_Tour_Mexico_SEP_24___es",
    path: "/es/ootbi-welcome-kit/Veeamon_Tour_Mexico_SEP_24",
    meta: Veeamon_Tour_Mexico_SEP_24ecbUbTqL8WMeta || {},
    component: () => import("/app/pages/ootbi-welcome-kit/Veeamon_Tour_Mexico_SEP_24.vue")
  },
  {
    name: "ootbi-welcome-kit-Veeamon_Tour_Mexico_SEP_24___it",
    path: "/it/ootbi-welcome-kit/Veeamon_Tour_Mexico_SEP_24",
    meta: Veeamon_Tour_Mexico_SEP_24ecbUbTqL8WMeta || {},
    component: () => import("/app/pages/ootbi-welcome-kit/Veeamon_Tour_Mexico_SEP_24.vue")
  },
  {
    name: "ootbi-welcome-kit-Veeamon_Tour_Mexico_SEP_24___pt",
    path: "/pt/ootbi-welcome-kit/Veeamon_Tour_Mexico_SEP_24",
    meta: Veeamon_Tour_Mexico_SEP_24ecbUbTqL8WMeta || {},
    component: () => import("/app/pages/ootbi-welcome-kit/Veeamon_Tour_Mexico_SEP_24.vue")
  },
  {
    name: "ootbi-welcome-kit-Veeamon_Tour_Sao_Paulo_AUG_29___en",
    path: "/ootbi-welcome-kit/Veeamon_Tour_Sao_Paulo_AUG_29",
    meta: Veeamon_Tour_Sao_Paulo_AUG_29aDHCfcdqspMeta || {},
    component: () => import("/app/pages/ootbi-welcome-kit/Veeamon_Tour_Sao_Paulo_AUG_29.vue")
  },
  {
    name: "ootbi-welcome-kit-Veeamon_Tour_Sao_Paulo_AUG_29___de",
    path: "/de/ootbi-welcome-kit/Veeamon_Tour_Sao_Paulo_AUG_29",
    meta: Veeamon_Tour_Sao_Paulo_AUG_29aDHCfcdqspMeta || {},
    component: () => import("/app/pages/ootbi-welcome-kit/Veeamon_Tour_Sao_Paulo_AUG_29.vue")
  },
  {
    name: "ootbi-welcome-kit-Veeamon_Tour_Sao_Paulo_AUG_29___fr",
    path: "/fr/ootbi-welcome-kit/Veeamon_Tour_Sao_Paulo_AUG_29",
    meta: Veeamon_Tour_Sao_Paulo_AUG_29aDHCfcdqspMeta || {},
    component: () => import("/app/pages/ootbi-welcome-kit/Veeamon_Tour_Sao_Paulo_AUG_29.vue")
  },
  {
    name: "ootbi-welcome-kit-Veeamon_Tour_Sao_Paulo_AUG_29___es",
    path: "/es/ootbi-welcome-kit/Veeamon_Tour_Sao_Paulo_AUG_29",
    meta: Veeamon_Tour_Sao_Paulo_AUG_29aDHCfcdqspMeta || {},
    component: () => import("/app/pages/ootbi-welcome-kit/Veeamon_Tour_Sao_Paulo_AUG_29.vue")
  },
  {
    name: "ootbi-welcome-kit-Veeamon_Tour_Sao_Paulo_AUG_29___it",
    path: "/it/ootbi-welcome-kit/Veeamon_Tour_Sao_Paulo_AUG_29",
    meta: Veeamon_Tour_Sao_Paulo_AUG_29aDHCfcdqspMeta || {},
    component: () => import("/app/pages/ootbi-welcome-kit/Veeamon_Tour_Sao_Paulo_AUG_29.vue")
  },
  {
    name: "ootbi-welcome-kit-Veeamon_Tour_Sao_Paulo_AUG_29___pt",
    path: "/pt/ootbi-welcome-kit/Veeamon_Tour_Sao_Paulo_AUG_29",
    meta: Veeamon_Tour_Sao_Paulo_AUG_29aDHCfcdqspMeta || {},
    component: () => import("/app/pages/ootbi-welcome-kit/Veeamon_Tour_Sao_Paulo_AUG_29.vue")
  },
  {
    name: "partner-rewards___en",
    path: "/partner-rewards",
    meta: partner_45rewardsT2Adcz84u9Meta || {},
    component: () => import("/app/pages/partner-rewards.vue")
  },
  {
    name: "partner-rewards___de",
    path: "/de/partner-rewards",
    meta: partner_45rewardsT2Adcz84u9Meta || {},
    component: () => import("/app/pages/partner-rewards.vue")
  },
  {
    name: "partner-rewards___fr",
    path: "/fr/partner-rewards",
    meta: partner_45rewardsT2Adcz84u9Meta || {},
    component: () => import("/app/pages/partner-rewards.vue")
  },
  {
    name: "partner-rewards___es",
    path: "/es/partner-rewards",
    meta: partner_45rewardsT2Adcz84u9Meta || {},
    component: () => import("/app/pages/partner-rewards.vue")
  },
  {
    name: "partner-rewards___it",
    path: "/it/partner-rewards",
    meta: partner_45rewardsT2Adcz84u9Meta || {},
    component: () => import("/app/pages/partner-rewards.vue")
  },
  {
    name: "partner-rewards___pt",
    path: "/pt/partner-rewards",
    meta: partner_45rewardsT2Adcz84u9Meta || {},
    component: () => import("/app/pages/partner-rewards.vue")
  },
  {
    name: "partners___en",
    path: "/partners",
    component: () => import("/app/pages/partners.vue")
  },
  {
    name: "partners___de",
    path: "/de/partners",
    component: () => import("/app/pages/partners.vue")
  },
  {
    name: "partners___fr",
    path: "/fr/partners",
    component: () => import("/app/pages/partners.vue")
  },
  {
    name: "partners___es",
    path: "/es/partners",
    component: () => import("/app/pages/partners.vue")
  },
  {
    name: "partners___it",
    path: "/it/partners",
    component: () => import("/app/pages/partners.vue")
  },
  {
    name: "partners___pt",
    path: "/pt/partners",
    component: () => import("/app/pages/partners.vue")
  },
  {
    name: "recorded-demo-code___en",
    path: "/recorded-demo/:code(.*)*",
    component: () => import("/app/pages/recorded-demo/[...code].vue")
  },
  {
    name: "recorded-demo-code___de",
    path: "/de/recorded-demo/:code(.*)*",
    component: () => import("/app/pages/recorded-demo/[...code].vue")
  },
  {
    name: "recorded-demo-code___fr",
    path: "/fr/recorded-demo/:code(.*)*",
    component: () => import("/app/pages/recorded-demo/[...code].vue")
  },
  {
    name: "recorded-demo-code___es",
    path: "/es/recorded-demo/:code(.*)*",
    component: () => import("/app/pages/recorded-demo/[...code].vue")
  },
  {
    name: "recorded-demo-code___it",
    path: "/it/recorded-demo/:code(.*)*",
    component: () => import("/app/pages/recorded-demo/[...code].vue")
  },
  {
    name: "recorded-demo-code___pt",
    path: "/pt/recorded-demo/:code(.*)*",
    component: () => import("/app/pages/recorded-demo/[...code].vue")
  },
  {
    name: "request-live-demo___en",
    path: "/request-live-demo",
    meta: request_45live_45demonXSANcgMVgMeta || {},
    component: () => import("/app/pages/request-live-demo.vue")
  },
  {
    name: "request-live-demo___de",
    path: "/de/request-live-demo",
    meta: request_45live_45demonXSANcgMVgMeta || {},
    component: () => import("/app/pages/request-live-demo.vue")
  },
  {
    name: "request-live-demo___fr",
    path: "/fr/request-live-demo",
    meta: request_45live_45demonXSANcgMVgMeta || {},
    component: () => import("/app/pages/request-live-demo.vue")
  },
  {
    name: "request-live-demo___es",
    path: "/es/request-live-demo",
    meta: request_45live_45demonXSANcgMVgMeta || {},
    component: () => import("/app/pages/request-live-demo.vue")
  },
  {
    name: "request-live-demo___it",
    path: "/it/request-live-demo",
    meta: request_45live_45demonXSANcgMVgMeta || {},
    component: () => import("/app/pages/request-live-demo.vue")
  },
  {
    name: "request-live-demo___pt",
    path: "/pt/request-live-demo",
    meta: request_45live_45demonXSANcgMVgMeta || {},
    component: () => import("/app/pages/request-live-demo.vue")
  },
  {
    name: resourceszAaIsuKYxcMeta?.name,
    path: "/resources",
    component: () => import("/app/pages/resources.vue"),
    children: [
  {
    name: "resources-category___en",
    path: ":category()",
    component: () => import("/app/pages/resources/[category].vue")
  },
  {
    name: "resources-case-studies___en",
    path: "case-studies",
    component: () => import("/app/pages/resources/case-studies/index.vue")
  },
  {
    name: "resources___en",
    path: "",
    component: () => import("/app/pages/resources/index.vue")
  }
]
  },
  {
    name: resourceszAaIsuKYxcMeta?.name,
    path: "/de/resources",
    component: () => import("/app/pages/resources.vue"),
    children: [
  {
    name: "resources-category___de",
    path: ":category()",
    component: () => import("/app/pages/resources/[category].vue")
  },
  {
    name: "resources-case-studies___de",
    path: "case-studies",
    component: () => import("/app/pages/resources/case-studies/index.vue")
  },
  {
    name: "resources___de",
    path: "",
    component: () => import("/app/pages/resources/index.vue")
  }
]
  },
  {
    name: resourceszAaIsuKYxcMeta?.name,
    path: "/fr/resources",
    component: () => import("/app/pages/resources.vue"),
    children: [
  {
    name: "resources-category___fr",
    path: ":category()",
    component: () => import("/app/pages/resources/[category].vue")
  },
  {
    name: "resources-case-studies___fr",
    path: "case-studies",
    component: () => import("/app/pages/resources/case-studies/index.vue")
  },
  {
    name: "resources___fr",
    path: "",
    component: () => import("/app/pages/resources/index.vue")
  }
]
  },
  {
    name: resourceszAaIsuKYxcMeta?.name,
    path: "/es/resources",
    component: () => import("/app/pages/resources.vue"),
    children: [
  {
    name: "resources-category___es",
    path: ":category()",
    component: () => import("/app/pages/resources/[category].vue")
  },
  {
    name: "resources-case-studies___es",
    path: "case-studies",
    component: () => import("/app/pages/resources/case-studies/index.vue")
  },
  {
    name: "resources___es",
    path: "",
    component: () => import("/app/pages/resources/index.vue")
  }
]
  },
  {
    name: resourceszAaIsuKYxcMeta?.name,
    path: "/it/resources",
    component: () => import("/app/pages/resources.vue"),
    children: [
  {
    name: "resources-category___it",
    path: ":category()",
    component: () => import("/app/pages/resources/[category].vue")
  },
  {
    name: "resources-case-studies___it",
    path: "case-studies",
    component: () => import("/app/pages/resources/case-studies/index.vue")
  },
  {
    name: "resources___it",
    path: "",
    component: () => import("/app/pages/resources/index.vue")
  }
]
  },
  {
    name: resourceszAaIsuKYxcMeta?.name,
    path: "/pt/resources",
    component: () => import("/app/pages/resources.vue"),
    children: [
  {
    name: "resources-category___pt",
    path: ":category()",
    component: () => import("/app/pages/resources/[category].vue")
  },
  {
    name: "resources-case-studies___pt",
    path: "case-studies",
    component: () => import("/app/pages/resources/case-studies/index.vue")
  },
  {
    name: "resources___pt",
    path: "",
    component: () => import("/app/pages/resources/index.vue")
  }
]
  },
  {
    name: "see-you-soon___en",
    path: "/see-you-soon",
    meta: see_45you_45soon0C9vOdD16QMeta || {},
    component: () => import("/app/pages/see-you-soon.vue")
  },
  {
    name: "see-you-soon___de",
    path: "/de/see-you-soon",
    meta: see_45you_45soon0C9vOdD16QMeta || {},
    component: () => import("/app/pages/see-you-soon.vue")
  },
  {
    name: "see-you-soon___fr",
    path: "/fr/see-you-soon",
    meta: see_45you_45soon0C9vOdD16QMeta || {},
    component: () => import("/app/pages/see-you-soon.vue")
  },
  {
    name: "see-you-soon___es",
    path: "/es/see-you-soon",
    meta: see_45you_45soon0C9vOdD16QMeta || {},
    component: () => import("/app/pages/see-you-soon.vue")
  },
  {
    name: "see-you-soon___it",
    path: "/it/see-you-soon",
    meta: see_45you_45soon0C9vOdD16QMeta || {},
    component: () => import("/app/pages/see-you-soon.vue")
  },
  {
    name: "see-you-soon___pt",
    path: "/pt/see-you-soon",
    meta: see_45you_45soon0C9vOdD16QMeta || {},
    component: () => import("/app/pages/see-you-soon.vue")
  },
  {
    name: "subscription-download-license-slug___en",
    path: "/subscription/download-license/:slug(.*)*",
    component: () => import("/app/pages/subscription/download-license/[...slug].vue")
  },
  {
    name: "subscription-download-license-slug___de",
    path: "/de/subscription/download-license/:slug(.*)*",
    component: () => import("/app/pages/subscription/download-license/[...slug].vue")
  },
  {
    name: "subscription-download-license-slug___fr",
    path: "/fr/subscription/download-license/:slug(.*)*",
    component: () => import("/app/pages/subscription/download-license/[...slug].vue")
  },
  {
    name: "subscription-download-license-slug___es",
    path: "/es/subscription/download-license/:slug(.*)*",
    component: () => import("/app/pages/subscription/download-license/[...slug].vue")
  },
  {
    name: "subscription-download-license-slug___it",
    path: "/it/subscription/download-license/:slug(.*)*",
    component: () => import("/app/pages/subscription/download-license/[...slug].vue")
  },
  {
    name: "subscription-download-license-slug___pt",
    path: "/pt/subscription/download-license/:slug(.*)*",
    component: () => import("/app/pages/subscription/download-license/[...slug].vue")
  },
  {
    name: "support___en",
    path: "/support",
    component: () => import("/app/pages/support.vue")
  },
  {
    name: "support___de",
    path: "/de/support",
    component: () => import("/app/pages/support.vue")
  },
  {
    name: "support___fr",
    path: "/fr/support",
    component: () => import("/app/pages/support.vue")
  },
  {
    name: "support___es",
    path: "/es/support",
    component: () => import("/app/pages/support.vue")
  },
  {
    name: "support___it",
    path: "/it/support",
    component: () => import("/app/pages/support.vue")
  },
  {
    name: "support___pt",
    path: "/pt/support",
    component: () => import("/app/pages/support.vue")
  },
  {
    name: "trust-center-slug___en",
    path: "/trust-center/:slug(.*)*",
    component: () => import("/app/pages/trust-center/[...slug].vue")
  },
  {
    name: "trust-center-slug___de",
    path: "/de/trust-center/:slug(.*)*",
    component: () => import("/app/pages/trust-center/[...slug].vue")
  },
  {
    name: "trust-center-slug___fr",
    path: "/fr/trust-center/:slug(.*)*",
    component: () => import("/app/pages/trust-center/[...slug].vue")
  },
  {
    name: "trust-center-slug___es",
    path: "/es/trust-center/:slug(.*)*",
    component: () => import("/app/pages/trust-center/[...slug].vue")
  },
  {
    name: "trust-center-slug___it",
    path: "/it/trust-center/:slug(.*)*",
    component: () => import("/app/pages/trust-center/[...slug].vue")
  },
  {
    name: "trust-center-slug___pt",
    path: "/pt/trust-center/:slug(.*)*",
    component: () => import("/app/pages/trust-center/[...slug].vue")
  },
  {
    name: "upcoming-partner-webinar___en",
    path: "/upcoming-partner-webinar",
    meta: upcoming_45partner_45webinard4DsYdgFyuMeta || {},
    component: () => import("/app/pages/upcoming-partner-webinar.vue")
  },
  {
    name: "upcoming-partner-webinar___de",
    path: "/de/upcoming-partner-webinar",
    meta: upcoming_45partner_45webinard4DsYdgFyuMeta || {},
    component: () => import("/app/pages/upcoming-partner-webinar.vue")
  },
  {
    name: "upcoming-partner-webinar___fr",
    path: "/fr/upcoming-partner-webinar",
    meta: upcoming_45partner_45webinard4DsYdgFyuMeta || {},
    component: () => import("/app/pages/upcoming-partner-webinar.vue")
  },
  {
    name: "upcoming-partner-webinar___es",
    path: "/es/upcoming-partner-webinar",
    meta: upcoming_45partner_45webinard4DsYdgFyuMeta || {},
    component: () => import("/app/pages/upcoming-partner-webinar.vue")
  },
  {
    name: "upcoming-partner-webinar___it",
    path: "/it/upcoming-partner-webinar",
    meta: upcoming_45partner_45webinard4DsYdgFyuMeta || {},
    component: () => import("/app/pages/upcoming-partner-webinar.vue")
  },
  {
    name: "upcoming-partner-webinar___pt",
    path: "/pt/upcoming-partner-webinar",
    meta: upcoming_45partner_45webinard4DsYdgFyuMeta || {},
    component: () => import("/app/pages/upcoming-partner-webinar.vue")
  },
  {
    name: "verify-email-slug___en",
    path: "/verify-email/:slug(.*)*",
    meta: _91_46_46_46slug_93ODBB1XchTcMeta || {},
    component: () => import("/app/pages/verify-email/[...slug].vue")
  },
  {
    name: "verify-email-slug___de",
    path: "/de/verify-email/:slug(.*)*",
    meta: _91_46_46_46slug_93ODBB1XchTcMeta || {},
    component: () => import("/app/pages/verify-email/[...slug].vue")
  },
  {
    name: "verify-email-slug___fr",
    path: "/fr/verify-email/:slug(.*)*",
    meta: _91_46_46_46slug_93ODBB1XchTcMeta || {},
    component: () => import("/app/pages/verify-email/[...slug].vue")
  },
  {
    name: "verify-email-slug___es",
    path: "/es/verify-email/:slug(.*)*",
    meta: _91_46_46_46slug_93ODBB1XchTcMeta || {},
    component: () => import("/app/pages/verify-email/[...slug].vue")
  },
  {
    name: "verify-email-slug___it",
    path: "/it/verify-email/:slug(.*)*",
    meta: _91_46_46_46slug_93ODBB1XchTcMeta || {},
    component: () => import("/app/pages/verify-email/[...slug].vue")
  },
  {
    name: "verify-email-slug___pt",
    path: "/pt/verify-email/:slug(.*)*",
    meta: _91_46_46_46slug_93ODBB1XchTcMeta || {},
    component: () => import("/app/pages/verify-email/[...slug].vue")
  },
  {
    name: "virtual-storage-appliance-slug___en",
    path: "/virtual-storage-appliance/:slug(.*)*",
    meta: _91_46_46_46slug_93Wh4YAnZy5SMeta || {},
    component: () => import("/app/pages/virtual-storage-appliance/[...slug].vue")
  },
  {
    name: "virtual-storage-appliance-slug___de",
    path: "/de/virtual-storage-appliance/:slug(.*)*",
    meta: _91_46_46_46slug_93Wh4YAnZy5SMeta || {},
    component: () => import("/app/pages/virtual-storage-appliance/[...slug].vue")
  },
  {
    name: "virtual-storage-appliance-slug___fr",
    path: "/fr/virtual-storage-appliance/:slug(.*)*",
    meta: _91_46_46_46slug_93Wh4YAnZy5SMeta || {},
    component: () => import("/app/pages/virtual-storage-appliance/[...slug].vue")
  },
  {
    name: "virtual-storage-appliance-slug___es",
    path: "/es/virtual-storage-appliance/:slug(.*)*",
    meta: _91_46_46_46slug_93Wh4YAnZy5SMeta || {},
    component: () => import("/app/pages/virtual-storage-appliance/[...slug].vue")
  },
  {
    name: "virtual-storage-appliance-slug___it",
    path: "/it/virtual-storage-appliance/:slug(.*)*",
    meta: _91_46_46_46slug_93Wh4YAnZy5SMeta || {},
    component: () => import("/app/pages/virtual-storage-appliance/[...slug].vue")
  },
  {
    name: "virtual-storage-appliance-slug___pt",
    path: "/pt/virtual-storage-appliance/:slug(.*)*",
    meta: _91_46_46_46slug_93Wh4YAnZy5SMeta || {},
    component: () => import("/app/pages/virtual-storage-appliance/[...slug].vue")
  },
  {
    name: "virtual-storage-appliance-setup-file-download___en",
    path: "/virtual-storage-appliance/setup-file-download",
    meta: setup_45file_45downloadD5FI3VUytXMeta || {},
    component: () => import("/app/pages/virtual-storage-appliance/setup-file-download.vue")
  },
  {
    name: "virtual-storage-appliance-setup-file-download___de",
    path: "/de/virtual-storage-appliance/setup-file-download",
    meta: setup_45file_45downloadD5FI3VUytXMeta || {},
    component: () => import("/app/pages/virtual-storage-appliance/setup-file-download.vue")
  },
  {
    name: "virtual-storage-appliance-setup-file-download___fr",
    path: "/fr/virtual-storage-appliance/setup-file-download",
    meta: setup_45file_45downloadD5FI3VUytXMeta || {},
    component: () => import("/app/pages/virtual-storage-appliance/setup-file-download.vue")
  },
  {
    name: "virtual-storage-appliance-setup-file-download___es",
    path: "/es/virtual-storage-appliance/setup-file-download",
    meta: setup_45file_45downloadD5FI3VUytXMeta || {},
    component: () => import("/app/pages/virtual-storage-appliance/setup-file-download.vue")
  },
  {
    name: "virtual-storage-appliance-setup-file-download___it",
    path: "/it/virtual-storage-appliance/setup-file-download",
    meta: setup_45file_45downloadD5FI3VUytXMeta || {},
    component: () => import("/app/pages/virtual-storage-appliance/setup-file-download.vue")
  },
  {
    name: "virtual-storage-appliance-setup-file-download___pt",
    path: "/pt/virtual-storage-appliance/setup-file-download",
    meta: setup_45file_45downloadD5FI3VUytXMeta || {},
    component: () => import("/app/pages/virtual-storage-appliance/setup-file-download.vue")
  },
  {
    name: "zero-trust-data-resilience-slug___en",
    path: "/zero-trust-data-resilience/:slug(.*)*",
    meta: _91_46_46_46slug_93zCgw7Pfr41Meta || {},
    component: () => import("/app/pages/zero-trust-data-resilience/[...slug].vue")
  },
  {
    name: "zero-trust-data-resilience-slug___de",
    path: "/de/zero-trust-data-resilience/:slug(.*)*",
    meta: _91_46_46_46slug_93zCgw7Pfr41Meta || {},
    component: () => import("/app/pages/zero-trust-data-resilience/[...slug].vue")
  },
  {
    name: "zero-trust-data-resilience-slug___fr",
    path: "/fr/zero-trust-data-resilience/:slug(.*)*",
    meta: _91_46_46_46slug_93zCgw7Pfr41Meta || {},
    component: () => import("/app/pages/zero-trust-data-resilience/[...slug].vue")
  },
  {
    name: "zero-trust-data-resilience-slug___es",
    path: "/es/zero-trust-data-resilience/:slug(.*)*",
    meta: _91_46_46_46slug_93zCgw7Pfr41Meta || {},
    component: () => import("/app/pages/zero-trust-data-resilience/[...slug].vue")
  },
  {
    name: "zero-trust-data-resilience-slug___it",
    path: "/it/zero-trust-data-resilience/:slug(.*)*",
    meta: _91_46_46_46slug_93zCgw7Pfr41Meta || {},
    component: () => import("/app/pages/zero-trust-data-resilience/[...slug].vue")
  },
  {
    name: "zero-trust-data-resilience-slug___pt",
    path: "/pt/zero-trust-data-resilience/:slug(.*)*",
    meta: _91_46_46_46slug_93zCgw7Pfr41Meta || {},
    component: () => import("/app/pages/zero-trust-data-resilience/[...slug].vue")
  },
  {
    name: "newsroom-media-kit___en",
    path: "/newsroom/media-kit",
    component: () => import("/app/pages/newsroom/media-kit/index.vue")
  },
  {
    name: "newsroom-media-kit___de",
    path: "/de/newsroom/media-kit",
    component: () => import("/app/pages/newsroom/media-kit/index.vue")
  },
  {
    name: "newsroom-media-kit___fr",
    path: "/fr/newsroom/media-kit",
    component: () => import("/app/pages/newsroom/media-kit/index.vue")
  },
  {
    name: "newsroom-media-kit___es",
    path: "/es/newsroom/media-kit",
    component: () => import("/app/pages/newsroom/media-kit/index.vue")
  },
  {
    name: "newsroom-media-kit___it",
    path: "/it/newsroom/media-kit",
    component: () => import("/app/pages/newsroom/media-kit/index.vue")
  },
  {
    name: "newsroom-media-kit___pt",
    path: "/pt/newsroom/media-kit",
    component: () => import("/app/pages/newsroom/media-kit/index.vue")
  },
  {
    name: "newsroom-press-releases-slug___en",
    path: "/newsroom/press-releases/:slug()",
    component: () => import("/app/pages/newsroom/press-releases/[slug].vue")
  },
  {
    name: "newsroom-press-releases-slug___de",
    path: "/de/newsroom/press-releases/:slug()",
    component: () => import("/app/pages/newsroom/press-releases/[slug].vue")
  },
  {
    name: "newsroom-press-releases-slug___fr",
    path: "/fr/newsroom/press-releases/:slug()",
    component: () => import("/app/pages/newsroom/press-releases/[slug].vue")
  },
  {
    name: "newsroom-press-releases-slug___es",
    path: "/es/newsroom/press-releases/:slug()",
    component: () => import("/app/pages/newsroom/press-releases/[slug].vue")
  },
  {
    name: "newsroom-press-releases-slug___it",
    path: "/it/newsroom/press-releases/:slug()",
    component: () => import("/app/pages/newsroom/press-releases/[slug].vue")
  },
  {
    name: "newsroom-press-releases-slug___pt",
    path: "/pt/newsroom/press-releases/:slug()",
    component: () => import("/app/pages/newsroom/press-releases/[slug].vue")
  },
  {
    name: "resources-analyst-reports-slug___en",
    path: "/resources/analyst-reports/:slug()",
    component: () => import("/app/pages/resources/analyst-reports/[slug].vue")
  },
  {
    name: "resources-analyst-reports-slug___de",
    path: "/de/resources/analyst-reports/:slug()",
    component: () => import("/app/pages/resources/analyst-reports/[slug].vue")
  },
  {
    name: "resources-analyst-reports-slug___fr",
    path: "/fr/resources/analyst-reports/:slug()",
    component: () => import("/app/pages/resources/analyst-reports/[slug].vue")
  },
  {
    name: "resources-analyst-reports-slug___es",
    path: "/es/resources/analyst-reports/:slug()",
    component: () => import("/app/pages/resources/analyst-reports/[slug].vue")
  },
  {
    name: "resources-analyst-reports-slug___it",
    path: "/it/resources/analyst-reports/:slug()",
    component: () => import("/app/pages/resources/analyst-reports/[slug].vue")
  },
  {
    name: "resources-analyst-reports-slug___pt",
    path: "/pt/resources/analyst-reports/:slug()",
    component: () => import("/app/pages/resources/analyst-reports/[slug].vue")
  },
  {
    name: "resources-case-studies-slug___en",
    path: "/resources/case-studies/:slug()",
    component: () => import("/app/pages/resources/case-studies/[slug].vue")
  },
  {
    name: "resources-case-studies-slug___de",
    path: "/de/resources/case-studies/:slug()",
    component: () => import("/app/pages/resources/case-studies/[slug].vue")
  },
  {
    name: "resources-case-studies-slug___fr",
    path: "/fr/resources/case-studies/:slug()",
    component: () => import("/app/pages/resources/case-studies/[slug].vue")
  },
  {
    name: "resources-case-studies-slug___es",
    path: "/es/resources/case-studies/:slug()",
    component: () => import("/app/pages/resources/case-studies/[slug].vue")
  },
  {
    name: "resources-case-studies-slug___it",
    path: "/it/resources/case-studies/:slug()",
    component: () => import("/app/pages/resources/case-studies/[slug].vue")
  },
  {
    name: "resources-case-studies-slug___pt",
    path: "/pt/resources/case-studies/:slug()",
    component: () => import("/app/pages/resources/case-studies/[slug].vue")
  },
  {
    name: "resources-technical-papers-data-sheet-ootbi-the-best-storage-for-veeam___en",
    path: "/resources/technical-papers/data-sheet-ootbi-the-best-storage-for-veeam",
    component: () => import("/app/pages/resources/technical-papers/data-sheet-ootbi-the-best-storage-for-veeam.vue")
  },
  {
    name: "resources-technical-papers-data-sheet-ootbi-the-best-storage-for-veeam___de",
    path: "/de/resources/technical-papers/data-sheet-ootbi-the-best-storage-for-veeam",
    component: () => import("/app/pages/resources/technical-papers/data-sheet-ootbi-the-best-storage-for-veeam.vue")
  },
  {
    name: "resources-technical-papers-data-sheet-ootbi-the-best-storage-for-veeam___fr",
    path: "/fr/resources/technical-papers/data-sheet-ootbi-the-best-storage-for-veeam",
    component: () => import("/app/pages/resources/technical-papers/data-sheet-ootbi-the-best-storage-for-veeam.vue")
  },
  {
    name: "resources-technical-papers-data-sheet-ootbi-the-best-storage-for-veeam___es",
    path: "/es/resources/technical-papers/data-sheet-ootbi-the-best-storage-for-veeam",
    component: () => import("/app/pages/resources/technical-papers/data-sheet-ootbi-the-best-storage-for-veeam.vue")
  },
  {
    name: "resources-technical-papers-data-sheet-ootbi-the-best-storage-for-veeam___it",
    path: "/it/resources/technical-papers/data-sheet-ootbi-the-best-storage-for-veeam",
    component: () => import("/app/pages/resources/technical-papers/data-sheet-ootbi-the-best-storage-for-veeam.vue")
  },
  {
    name: "resources-technical-papers-data-sheet-ootbi-the-best-storage-for-veeam___pt",
    path: "/pt/resources/technical-papers/data-sheet-ootbi-the-best-storage-for-veeam",
    component: () => import("/app/pages/resources/technical-papers/data-sheet-ootbi-the-best-storage-for-veeam.vue")
  },
  {
    name: "technical___en",
    path: "/technical",
    component: () => import("/app/pages/blog/category.vue")
  },
  {
    name: "technical___de",
    path: "/de/technical",
    component: () => import("/app/pages/blog/category.vue")
  },
  {
    name: "technical___fr",
    path: "/fr/technical",
    component: () => import("/app/pages/blog/category.vue")
  },
  {
    name: "technical___es",
    path: "/es/technical",
    component: () => import("/app/pages/blog/category.vue")
  },
  {
    name: "technical___it",
    path: "/it/technical",
    component: () => import("/app/pages/blog/category.vue")
  },
  {
    name: "technical___pt",
    path: "/pt/technical",
    component: () => import("/app/pages/blog/category.vue")
  },
  {
    name: "business___en",
    path: "/business",
    component: () => import("/app/pages/blog/category.vue")
  },
  {
    name: "business___de",
    path: "/de/business",
    component: () => import("/app/pages/blog/category.vue")
  },
  {
    name: "business___fr",
    path: "/fr/business",
    component: () => import("/app/pages/blog/category.vue")
  },
  {
    name: "business___es",
    path: "/es/business",
    component: () => import("/app/pages/blog/category.vue")
  },
  {
    name: "business___it",
    path: "/it/business",
    component: () => import("/app/pages/blog/category.vue")
  },
  {
    name: "business___pt",
    path: "/pt/business",
    component: () => import("/app/pages/blog/category.vue")
  }
]