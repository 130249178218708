import { useDayjs } from '#dayjs';

export default defineNuxtPlugin(app => {
  const dayjs = useDayjs();

  // set locale on app created
  app.hook('app:created', () => {
    dayjs.locale(app.$i18n.locale.value);
  });

  // set locale on locale switched
  app.hook('i18n:localeSwitched', ({ newLocale: locale }) => dayjs.locale(locale));

  dayjs.updateLocale('fr', {
    ordinal: (n: number) => {
      const o = n === 1 ? 'er' : 'e';
      return `${n}${o}`
    }
  });
});
