// constants
import { API_ENDPOINTS } from '~/constants';

// types
import type { DecodedJWT, PlainObject } from '~/types';

export const byUsedId = async (userId: string): Promise<string | undefined> => {
  const {
    public: { api_v2: baseURL },
  } = useRuntimeConfig();

  const response = await $fetch<{ jwt?: string }>(
    `${API_ENDPOINTS.USER_DATA}/${userId}`,
    { baseURL, ignoreResponseError: true },
  );

  return response.jwt;
};

export const encode = async (body: PlainObject): Promise<string> => {
  const {
    public: { api_v2: baseURL },
  } = useRuntimeConfig();

  const response = await $fetch<{ jwt: string }>(API_ENDPOINTS.JWT_ENCODE, {
    body,
    baseURL,
    method: 'POST',
    ignoreResponseError: true,
  });

  return response.jwt;
};

export const decode = async (
  token: string,
): Promise<DecodedJWT['data'] | undefined> => {
  const response = await $fetch<DecodedJWT>(API_ENDPOINTS.JWT_DECODE, {
    method: 'POST',
    body: { token },
    ignoreResponseError: true,
  });

  return response.data;
};
