export const REQUEST_DEMO = 'RequestDemo';
export const GET_FREE_DEMO = 'GetFreeDemo';
export const REQUEST_DEMO_FORM = 'request-demo-form';
export const USER_ID = 'user_id';
export const HELP_FEEDBACK_SUBMITTED = 'help-feedback-submitted';
export const BREAKING_NEWS_BANNER = 'is-banner-hidden';
export const SUBMITTED_PARAMS = 'submitted_params';

// a query params with wich user went to the page
export const QUERY_PARAMS = 'aqpwwtuwttp';

// definitely not real user data
export const JWT = 'dnrud';

// this is Google Analytics session id
export const GTAG_SESSION_ID = 'tigasi';
