// https://gearboxgo.com/articles/web-application-development/setting-up-sentry-with-nuxt-3
// https://www.lichter.io/articles/nuxt3-sentry-recipe/
// https://docs.sentry.io/platforms/javascript/guides/vue/configuration/options/#attach-stacktrace
// https://docs.sentry.io/platforms/javascript/guides/vue/configuration/options/#ignore-errors
// https://docs.sentry.io/platforms/javascript/guides/vue/configuration/options/#deny-urls

import * as Sentry from '@sentry/vue';
import { version } from '~/package.json';

export default defineNuxtPlugin(({ vueApp: app }) => {
  const router = useRouter();
  const { public: { sentry: { dsn, environment } } } = useRuntimeConfig();

  if (!dsn || environment !== 'production') {
    return;
  }

  Sentry.init({
    app,
    dsn,
    environment,
    release: version,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],

    // Controlling the percentage chance a given transaction will be sent to Sentry (0.1 = 10%)
    tracesSampleRate: 1,

    // back-end server which can response with "Sentry-Trace" header
    tracePropagationTargets: [],

    replaysSessionSampleRate: 0.1, // Capture Replay for (0.1 - 10%) of all sessions
    replaysOnErrorSampleRate: 1, // Capture Replay for (0.1 - 10%) of sessions with an error

    denyUrls: [
      'analytics.google.com',
      'google-analytics.com',
      'redditstatic.com',
      'pixel-config.reddit.com',
      'px.ads.linkedin.com',
      'monitor.clickcease.com',
      'obs.esnbranding.com',
      'tags.srv.stackadapt.com',
      'api-cdn.chatlio.com',
      'tattle.api.osano.com',
    ],

    ignoreErrors: [
      'Non-Error promise rejection captured with value:',
    ],
  });
});
