import type { GTag } from '~/gtag';

export default defineNuxtPlugin(() => {
  const gtag: GTag = function () {
    (window.gtag || (() => {}))(...arguments);
  };

  return {
    provide: {
      gtag,
    },
  };
});
