<template>
  <div
    :class="[
      size,
      fill,
      {
        'has-focus': hasFocus,
        'has-value': hasValue,
        error,
        success,
        disabled,
      }
    ]"
    data-component-name="AppInput"
  >
    <div class="wrapper">
      <label
        v-if="label"
        :for="id"
        class="placeholder"
      >
        {{ label + (required ? '*' : '') }}
      </label>

      <input
        v-model="modelValue"
        ref="input"
        :type
        :disabled
        :placeholder
        :maxlength
        :name
        :id
        @focus="toggleFocus(true)"
        @blur="toggleFocus(false)"
      >
    </div>

    <span
      v-if="error"
      v-html="error"
      class="error"
    />

    <span
      v-if="!error && message"
      v-html="message"
      :class="[success ? 'success' : 'error' ]"
    />
  </div>
</template>

<script setup lang="ts">
const id = useId();

withDefaults(
  defineProps<{
    size?: 'small' | 'medium' | 'large'
    fill?: 'solid' | 'outline'

    type?: 'text' | 'email' | 'search'
    label?: string
    placeholder?: string
    maxlength?: string | number
    required?: boolean
    disabled?: boolean
    error?: string
    success?: string
    message?: string
    name?: string
  }>(),
  {
    size: 'large',
    fill: 'solid',

    type: 'text',
    required: false,
    disabled: false,
  },
);

const modelValue = defineModel<string>({ default: '' });

const hasFocus = ref(false);
const hasValue = ref(false);

const toggleFocus = (value: boolean) => {
  hasFocus.value = value;
};
const toggleValue = (value: boolean) => {
  hasValue.value = value;
};

watch(modelValue, value => toggleValue(value.length > 0));
onMounted(() => toggleValue(modelValue.value.length > 0));

const inputRef = useTemplateRef<HTMLInputElement>('input');

defineExpose({
  focus: () => inputRef.value?.focus(),
  blur: () => inputRef.value?.blur(),
});
</script>

<style scoped lang="scss">
@use "_/mixins/text-transform";
@use "_/mixins/typo";
@use "_/fn";

[data-component-name="AppInput"] {
  width: 100%;

  .wrapper {
    position: relative;

    label {
      color: fn.token('text-secondary');
      @include typo.body-3;

      position: absolute;
      left: 1rem;
      top: 50%;

      transform: translateY(-50%);
      pointer-events: none;

      transition: all 0.15s ease;
      will-change: color, top, padding;
    }

    input {
      width: 100%;
      border: 1px solid transparent;
      caret-color: fn.token('outline-action');

      @include typo.body-3;

      transition: all 0.15s ease;
      will-change: background-color, border-color;

      &::placeholder {
        opacity: 0;
        transition: opacity 0.15s ease;
      }

      &:hover {
        background-color: color-mix(
          in srgb,
          fn.token('accent-hover') 16%,
          fn.token('surf-cont-secondary')
        );
      }

      &[type="search"]::-webkit-search-decoration,
      &[type="search"]::-webkit-search-cancel-button,
      &[type="search"]::-webkit-search-results-button,
      &[type="search"]::-webkit-search-results-decoration {
        -webkit-appearance:none;
      }
    }
  }

  span {
    @include typo.caption;
    @include text-transform.capitalize-first;
    margin: 0.25rem 0 0 1rem;
    display: block;

    &.success {
      color: fn.token('text-success');

    }

    &.error {
      color: fn.token('error');
    }
  }

  &.has-focus,
  &.has-value {
    .wrapper {
      label {
        top: 0;
        padding: 0 0.25rem;
        color: fn.token('text-primary');
        background-color: fn.token('surf-cont-primary');

        z-index: 1;
      }
    }
  }

  &.has-focus {
    .wrapper {
      input {
        border-color: fn.token('outline-action');

        &::placeholder {
          opacity: 1;
        }
      }
    }
  }

  &.success {
    .wrapper {
      label {}

      input {
        border-color: fn.token('text-success');
      }
    }
  }

  &.error {
    .wrapper {
      label {}

      input {
        border-color: fn.token('error');
      }
    }
  }

  &.disabled {
    .wrapper {
      label {
        color: fn.token('text-tertiary');
      }

      input {
        cursor: not-allowed;
        color: fn.token('text-tertiary');

        background-color: color-mix(
          in srgb,
          fn.token('neutral-disabled') 60%,
          fn.token('surf-cont-secondary')
        );
      }
    }
  }

  // SIZES
  &.small {
    input {
      padding: 0.5rem 1rem;
    }
  }

  &.medium {
    input {
      padding: 0.75rem 1rem;
    }
  }

  &.large {
    input {
      padding: 1rem;
    }
  }

  // FILL
  &.solid {
    input {
      background-color: fn.token('surf-cont-secondary');
    }
  }

  &.outline {
    input {
      border-color: fn.token('outline-secondary');
    }
  }
}
</style>
