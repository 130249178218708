<template>
  <ul data-component-name="SocialLinks">
    <li
      v-for="item of ITEMS"
      :key="item.icon"
    >
      <Buttons.Link
        :accent
        :fill
        :to="item.link"
        :aria-label="item.ariaLabel"
        target="_blank"
      >
        <SvgIcon :name="item.icon" />
      </Buttons.Link>
    </li>
  </ul>
</template>

<script setup lang="ts">
// components
import Buttons from '~/components/redesign/Buttons';

// constants
import { OBJECT_FIRST } from '~/constants/contacts';

// types
import type { BaseButtonProps } from '~/components/redesign/Buttons/Base.types';

withDefaults(
  defineProps<Partial<Omit<BaseButtonProps, 'disabled'>>>(),
  {
    fill: 'outline',
    accent: 'white',
  },
);

const ITEMS = [
  {
    icon: 'linked-in',
    link: OBJECT_FIRST.LINK.LINKED_IN,
    ariaLabel: 'Go to ObjectFirst LinkedIn page',
  },
  {
    icon: 'x',
    link: OBJECT_FIRST.LINK.X,
    ariaLabel: 'Go to ObjectFirst X page',
  },
  {
    icon: 'youtube',
    link: OBJECT_FIRST.LINK.YOUTUBE,
    ariaLabel: 'Go to ObjectFirst YouTube page',
  },
  {
    icon: 'instagram',
    link: OBJECT_FIRST.LINK.INSTAGRAM,
    ariaLabel: 'Go to ObjectFirst Instagram page',
  },
];
</script>

<style scoped lang="scss">
@import "$/mixins/flex";
@import "$/mixins/size";
@import "$/functions/token";

ul[data-component-name="SocialLinks"] {
  @include flex-center-sb;
  gap: 0 1rem;

  li {
    a {
      svg {
        @include fixed-size(1.5rem);
      }
    }
  }
}
</style>
