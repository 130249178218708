// DOCS: https://javascript.info/cookie
import { COOKIE_KEYS } from '~/constants';
import { getRootDomain } from '~/utils';

export default class Cookie {
  /**
   * Returns the cookie with the given name, or undefined if not found
   *
   * @param  {string} name - cookie name
   * @returns string | null
   */
  public static get(name: string) {
    const matches = document.cookie.match(
      new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"),
    );

    return matches
      ? decodeURIComponent(matches[1])
      : undefined;
  }

  /**
   * Please note that a cookie value is encoded,
   * so getCookie uses a built-in decodeURIComponent function to decode it.
   *
   * @param  {string} name - cookie name
   * @param  {string | number | boolean} value - cookie value
   * @param  {any} cookieOptions - cookie options
   * @returns void
   */
  public static set(
    name: string,
    value: string | number | boolean,
    cookieOptions?: any,
  ): void {
    const options = {
      path: '/',
      domain: '.' + getRootDomain(),
      expires: Cookie.getDefaultExpirationDate(),
      SameSite: 'None; Secure',

      ...cookieOptions,
    };

    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (const optionKey in options) {
      updatedCookie += "; " + optionKey;
      const optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += "=" + optionValue;
      }
    }

    document.cookie = updatedCookie;
    debug('Cookie.set', name, value);
  }

  /**
   * To delete a cookie, we can call it with a negative expiration date
   *
   * @param  {string} name
   */
  public static delete(name: string): void {
    Cookie.set(name, "", { "max-age": -1 });
    Cookie.set(name, "", { "max-age": -1, domain: "" });
  }

  public static getCookieArray() {
    return {
      c_of_utm_source: Cookie.get("c_of_utm_source"),
      c_of_utm_medium: Cookie.get("c_of_utm_medium"),
      c_of_utm_campaign: Cookie.get("c_of_utm_campaign"),
      c_of_utm_content: Cookie.get("c_of_utm_content"),
      c_of_utm_term: Cookie.get("c_of_utm_term"),
      c_of_gclid: Cookie.get("c_of_gclid"),
      c_of_leadId: Cookie.get("c_of_leadId"),
      c_of__ga: Cookie.get("c_of__ga"),
      _ga: Cookie.get("_ga"),
      _fbp: Cookie.get("_fbp") ?? null,
      _fbc: Cookie.get("_fbc") ?? null,

      cookie: document.cookie,
      [COOKIE_KEYS.QUERY_PARAMS]: this.get(COOKIE_KEYS.QUERY_PARAMS),
    };
  }

  public static clearAfterSubmit(): void {
    this.delete('c_of__ga');
    this.delete('c_of_utm_campaign');
    this.delete('c_of_utm_content');
    this.delete('c_of_utm_medium');
    this.delete('c_of_utm_source');
    this.delete('c_of_utm_term');
  }

  private static getDefaultExpirationDate(): Date {
    const date = new Date();
    date.setDate(date.getDate() + 365);

    return date;
  }
}
