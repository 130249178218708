<template>
  <noscript>
    <iframe
      v-if="env === 'production'"
      :src="`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`"
      height="0"
      width="0"
      style="display:none;visibility:hidden"
    />
  </noscript>

  <NuxtLayout>
    <NuxtPage/>
  </NuxtLayout>

  <AppOverlay id="overlay" v-bind="overlay" />
  <AppLoader v-if="isLoading" />

  <ClientOnly>
    <TheChatlio />
  </ClientOnly>
</template>

<script setup lang="ts">
// components
import AppLoader from '~/components/redesign/AppLoader.vue';
import AppOverlay from '~/components/redesign/AppOverlay.vue';
import TheChatlio from '~/components/redesign/TheChatlio.vue'

// composable
import { useI18n } from '#i18n';
import { useDayjs } from '#dayjs';
import { storeToRefs } from 'pinia';
import { useAbsoluteUrl } from '~/composables/absoluteUrl';

// utils
import isObject from '~/utils/isObject';
import { isNotEmptyString } from '~/utils';
import isValidJSON from '~/utils/isValidJSON';
import isValidIpInfo from '~/utils/isValidIpInfo';

// store
import useUIStore from '~/stores/ui';
import { useCommonStore } from '~/stores/common';

// services
import { Cookie } from '~/services';

// constants
import { COOKIE_KEYS } from '~/constants';

// types
import type { IpInfo } from '~/types/api-responses';

const dayjs = useDayjs();
const route = useRoute();
const { t, locale } = useI18n();
const { getAbsoluteUrl } = useAbsoluteUrl();
const { public: { env, GTM_ID, GA_ID } } = useRuntimeConfig();
const { $osano, $gtag } = useNuxtApp();

const uiStore = useUIStore();
const { setBannerAllowed } = uiStore;
const { isLoading, overlay, isScrollLocked } = storeToRefs(uiStore);

const commonStore = useCommonStore();
const { requestIpInfo, setIpInfo } = commonStore;

const getIpInfoFromCookie = (): IpInfo | undefined => {
  const pureIpInfoFromCookie = Cookie.get('ip_info');

  if (!isValidJSON(pureIpInfoFromCookie)) return;

  const parsedIpInfoFromCookie = JSON.parse(pureIpInfoFromCookie);

  if (!isObject(parsedIpInfoFromCookie) || !isValidIpInfo(parsedIpInfoFromCookie)) return;

  return parsedIpInfoFromCookie;
};

onMounted(async () => {
  const ipInfoFromCookie = getIpInfoFromCookie();

  if (ipInfoFromCookie) setIpInfo(ipInfoFromCookie);
  else {
    try {
      const response = await requestIpInfo();

      if (response) {
        setIpInfo(response);
        Cookie.set('ip_info', JSON.stringify(response), {
          expires: dayjs().add(7, 'days').toDate(),
        });
      }
    } catch {
      void clearError();
    }
  }
});

onMounted(() => {
  if (env !== 'production') {
    setBannerAllowed(true)
    return
  }

  const consent = $osano.cm.getConsent();
  if ('ESSENTIAL' in consent) {
    nextTick(() => setBannerAllowed(true));
  }

  $osano.cm.addEventListener('osano-cm-ui-changed', (component, stateChange) => {
    if (['dialog', 'drawer'].includes(component) && stateChange === 'hide') {
      nextTick(() => setBannerAllowed(true));
    }
  })
});

onMounted(() => {
  $gtag('get', GA_ID, 'session_id', value => {
    if (isNotEmptyString(value)) {
      Cookie.set(COOKIE_KEYS.GTAG_SESSION_ID, value);
    }
  });
});

watchEffect(() => {
  if (import.meta.server) return;

  document.body.classList[
    isScrollLocked.value ? 'add' : 'remove'
  ]('no-scroll');
});

useHead({
  link: [{ rel: 'canonical', href: getAbsoluteUrl(route.path) }],
  title: () => t('index.seo.title'),
  meta: [
    { name: 'description', content: () => t('index.seo.description') },
    { property: 'og:image', content: 'https://objectfirst.nyc3.digitaloceanspaces.com/Metadata/OG_Banner_General_360x169.png' },
  ],
  htmlAttrs: {
    lang: locale,
  },
});

useSchemaOrg([
  defineWebSite({ image: 'https://objectfirst.nyc3.digitaloceanspaces.com/Metadata/OG_Banner_General_360x169.png' }),
  defineImage({
    url: 'https://objectfirst.b-cdn.net/uploads/logos/logo-white.svg',
    width: 103,
    height: 51,
    caption: 'Object First',
  }),
]);
</script>

<style scoped lang="scss">
[data-component-name="AppOverlay"] {
  padding: 1rem;
}
</style>
