import { useLocalePath } from '#i18n';
import type { Locale } from '#i18n';
import { isMediaFilePath } from '~/utils';

export default function useLocalePathWithSlash(): (value: string, locale?: Locale) => string {
  const localePath = useLocalePath();

  return (value, locale) => {
    let path = value;

    if (isMediaFilePath(path)) {
      if (path.endsWith('/')) {
        path = path.slice(0, -1);
      }
    } else {
      path = localePath(value, locale);

      if (!path.endsWith('/')) {
        path = path + '/';
      }
    }

    return path;
  };
}
