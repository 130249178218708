import { readonly } from 'vue';
import { defineStore } from 'pinia';

// constants
import { COOKIE_KEYS, OFFSETS } from '~/constants';

// types
import type { AppOverlayProps } from '~/components/redesign/AppOverlay.vue';

// services
import { Cookie } from '~/services';
import { useDayjs } from '#dayjs';

const useUIStore = defineStore('ui', () => {
const dayjs = useDayjs();

  const isLoading = ref(false);
  const setLoading = (value: boolean) => {
    isLoading.value = value;
  };

  const overlay = ref<AppOverlayProps>({ visible: false });
  const setOverlay = (props: AppOverlayProps) => {
    overlay.value = { ...overlay.value, ...props };
  };

  const isMobileMenuVisible = ref(false);
  const setMobileMenuVisibility = (value: boolean) => {
    isMobileMenuVisible.value = value;
  };

  const isSearchVisible = ref(false);
  const setSearchVisibility = (value: boolean) => {
    isSearchVisible.value = value;
  };

  const isBannerAllowed = ref(false);
  const setBannerAllowed = (value: boolean) => {
    isBannerAllowed.value = value
  };

  const isBannerDataLoaded = ref(false);
  const setBannerDataLoaded = (value: boolean) => {
    isBannerDataLoaded.value = value;
  };

  const isBannerClosed = useCookie(COOKIE_KEYS.BREAKING_NEWS_BANNER);
  const closeBanner = () => Cookie.set(COOKIE_KEYS.BREAKING_NEWS_BANNER, true, {
    expires: dayjs().add(1, 'month').toDate(),
  });

  const shouldDisplayBreakingNewsBanner = computed(() =>
    isBannerAllowed.value &&
    isBannerDataLoaded.value &&
    !isBannerClosed.value);

  const wrapperOffset = computed(() => {
    if (import.meta.server) return;

    return shouldDisplayBreakingNewsBanner.value
      ? OFFSETS.BANNER + OFFSETS.HEADER + OFFSETS.REM_UNIT
      : OFFSETS.HEADER + OFFSETS.REM_UNIT;
  });

  const isLanguageSelectorVisible = ref(true);
  const setLanguageSelectorVisibility = (value: boolean) => {
    isLanguageSelectorVisible.value = value;
  };

  const isScrollLocked = computed(() => isLoading.value || overlay.value.visible || isMobileMenuVisible.value);

  return {
    isLoading: readonly(isLoading),
    setLoading,

    overlay: readonly(overlay),
    setOverlay,

    isMobileMenuVisible: readonly(isMobileMenuVisible),
    setMobileMenuVisibility,

    isSearchVisible: readonly(isSearchVisible),
    setSearchVisibility,

    isLanguageSelectorVisible: readonly(isLanguageSelectorVisible),
    setLanguageSelectorVisibility,

    shouldDisplayBreakingNewsBanner,
    setBannerDataLoaded,
    setBannerAllowed,
    closeBanner,

    isScrollLocked,

    headerOffset: wrapperOffset,
  };
});

export default useUIStore;
