const DEFAULT_CONSENT = {
  'OPT-OUT': 'DENY',
  'STORAGE': 'DENY',
  'ANALYTICS': 'DENY',
  'MARKETING': 'DENY',
  'PERSONALIZATION': 'DENY',
  'ESSENTIAL': 'ACCEPT',
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      osano: (() => {
        window.Osano ||= function () {window.Osano.data.push(arguments)};
        window.Osano.data ||= [];
        window.Osano.cm ||= {};
        window.Osano.cm.getConsent ||= () => DEFAULT_CONSENT;
        window.Osano.cm.addEventListener ||= () => {};

        return window.Osano;
      })(),
    },
  };
});
