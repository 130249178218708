export const EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const YOUTUBE_VIDEO_ID = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;

export const SHORT_DATE = /(\d{4})-(\d{2})-(\d{2})/;
export const FULL_DATE = new RegExp(SHORT_DATE.source + '\\s(\\d{2}):(\\d{2}):(\\d{2})');

export const KEY_CODE = /^[0-9a-zA-Z]{5}$/;
export const SERIAL_NUMBER = /^[a-zA-Z0-9_-]+$/;

export const HELP_VERSION = /^(\d+\.\d+(\.\d+)?)$/;

export const FILE_PATH = /\.(pdf|html)$/;

export const O1_DOMAIN = /^https?:\/\/(([a-z0-9-]+\.)?objectfirst\.(com|app)|localhost)(:\d{4})?/mg;
