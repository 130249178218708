<template>
  <NuxtLink
    :to="localePathWithSlash(PATHS.PRIVACY_POLICY.path)"
    :class="['nowrap', accent]"
    target="_blank"
    data-component-name="LinkPrivacyNotice"
  >
    {{ $t(PATHS.PRIVACY_POLICY.i18nKey) }}
  </NuxtLink>
</template>

<script setup lang="ts">
// constants
import { PATHS } from '~/constants/nav-items';

// composables
import useLocalePathWithSlash from '~/composables/useLocalePathWithSlash';

// types
import type { NuxtLinkProps } from '#app/components/nuxt-link';

type Props = Omit<NuxtLinkProps, 'to' | 'target'> & { accent?: 'dark' | 'light' }

withDefaults(
  defineProps<Props>(),
  {
    accent: 'dark',
  },
);

const localePathWithSlash = useLocalePathWithSlash();
</script>

<style scoped lang="scss">
@import "$/mixins/typography";
@import "$/mixins/components";

[data-component-name="LinkPrivacyNotice"] {
  &.dark {
    @include app-link('link');
    @include caption;
  }

  &.light {
    @include app-link('link-reversed');
    @include caption;
  }
}
</style>
