<template>
  <vue-awesome-paginate
    data-component-name="AppPagination"
    v-model="modelValue"
    :total-items="totalItems"
    :items-per-page="itemsPerPage"
    :max-pages-shown="maxPagesShown"
  >
    <template #prev-button>
      <SvgIcon name="chevron-left" />
      {{ $t('Back') }}
    </template>

    <template #next-button>
      {{ $t('Next') }}
      <SvgIcon name="chevron-right" />
    </template>
  </vue-awesome-paginate>
</template>

<script setup lang="ts">
import { VueAwesomePaginate } from 'vue-awesome-paginate';

const modelValue = defineModel('modelValue');

withDefaults(
  defineProps<{
    totalItems: number
    itemsPerPage: number
    maxPagesShown?: number
  }>(),
  {
    maxPagesShown: 4,
  },
);
</script>

<style scoped lang="scss">
@import "$/mixins/flex";
@import "$/mixins/media";
@import "$/mixins/typography";
@import "$/functions/token";

[data-component-name="AppPagination"] {
  @include flex-center-center;
  gap: 0.5rem;

  &:deep(li) {
    .back-button,
    .next-button {
      @include flex-center-center;
      gap: 0.25rem;

      @include mobile {
        display: none;
      }
    }

    .back-button {
      margin-right: 0.5rem;
    }

    .next-button {
      margin-left: 0.5rem;
    }

    .last-button {}

    .ending-breakpoint-button {}

    .paginate-buttons {
      padding: 0.5rem;
      @include subtitle-3;
      color: token('text-primary');

      &:not(.active-page):hover {
        background-color: rgba(token('accent-hover'), 16%);
      }

      &:not(.active-page):active {
        background-color: rgba(token('accent-hover'), 32%);
      }
    }

    .number-buttons {}

    .active-page {
      color: token('text-reversed');
      background-color: token('surf-cont-prim-action');
    }
  }
}
</style>
