import isValidURL from './isValidURL';

export default function getRootDomain(value = window.location.href) {
  if (!isValidURL(value)) return;

  const { hostname } = new URL(value);
  const splitterHostName = hostname.split('.');

  return splitterHostName.length < 2
    ? hostname
    : splitterHostName.slice(-2).join('.');
}
