// https://github.com/nuxt/image/blob/main/src/runtime/providers/ipx.ts

import { joinURL, encodePath, encodeParam } from 'ufo';
import { createOperationsGenerator } from '#image'
import type { ProviderGetImage } from '@nuxt/image';
import { isValidURL } from '~/utils';

const operationsGenerator = createOperationsGenerator({
  keyMap: {
    format: 'f',
    fit: 'fit',
    width: 'w',
    height: 'h',
    resize: 's',
    quality: 'q',
    background: 'b',
  },
  joinWith: '&',
  formatter: (key, val) => encodeParam(key) + '_' + encodeParam(val),
});

export const getImage: ProviderGetImage = (src, { modifiers = {}, baseURL } = {}) => {
  if (modifiers.width && modifiers.height) {
    modifiers.resize = `${modifiers.width}x${modifiers.height}`;

    delete modifiers.width;
    delete modifiers.height;
  }

  let url: string;
  const params = operationsGenerator(modifiers) || '_';

  if (isValidURL(src)) {
    url = src;
  } else {
    url = src.startsWith('/uploads') ? baseURL : '/';
    url = joinURL(url, '_ipx', params, encodePath(src));
  }

  return {
    url,
  }
}
