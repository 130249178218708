import * as yup from 'yup';

// composables
import { useDayjs } from '#dayjs';

// services
import { Cookie, JWT } from '~/services';

// utils
import get from 'lodash.get';
import { validate } from 'uuid';
import { getQueryParam, isString, isValidJSON } from '~/utils';

// constants
import { COOKIE_KEYS, REGEX } from '~/constants';

// types
import type { PlainObject } from '~/types';

const USER_DATA_KEYS_MAP = {
  first_name: 'firstname',
  last_name: 'lastname',
  full_name: 'fullname',
} as PlainObject<string>;

const stringSchema = yup.string().trim().max(128).required();
const submittedParamsValidationSchema = yup.object({
  firstname: stringSchema,
  lastname: stringSchema,
  email: stringSchema.matches(REGEX.EMAIL).email(),
});

const isUUID = (payload: unknown): payload is string => validate(payload);

const handleUserIdQuery = async () => {
  const {
    public: { pp_query_key },
  } = useRuntimeConfig();
  const userId = getQueryParam(pp_query_key);

  if (isUUID(userId)) {
    const jwt = await JWT.byUsedId(userId);

    if (isString(jwt)) {
      Cookie.set(COOKIE_KEYS.JWT, jwt);
      Cookie.set(COOKIE_KEYS.USER_ID, userId);
    }
  }
};

const migrateSubmittedParams = async () => {
  const jwtFromCookie = Cookie.get(COOKIE_KEYS.JWT);

  if (isString(jwtFromCookie)) {
    const decodedData = await JWT.decode(jwtFromCookie);
    const isOurJWT = decodedData !== undefined;

    if (isOurJWT) {
      Cookie.delete(COOKIE_KEYS.SUBMITTED_PARAMS);
    } else {
      Cookie.delete(COOKIE_KEYS.JWT);
    }

    // TODO: re-encode if expired
  }

  const submittedParamsFromCookie = Cookie.get(COOKIE_KEYS.SUBMITTED_PARAMS);

  if (isValidJSON(submittedParamsFromCookie)) {
    try {
      const submittedParams = await submittedParamsValidationSchema.validate(
        JSON.parse(submittedParamsFromCookie),
      );

      const jwtFromServer = await JWT.encode(submittedParams);
      const dayjs = useDayjs();

      Cookie.set(COOKIE_KEYS.JWT, jwtFromServer, {
        expires: dayjs().add(1, 'year').toDate(),
      });
    } finally {
      Cookie.delete(COOKIE_KEYS.SUBMITTED_PARAMS);
    }
  }
};

const normalizeKeys = (payload: PlainObject) =>
  Object.entries(payload).reduce<PlainObject>((acc, [oldKey, value]) => {
    const newKey: undefined | string = USER_DATA_KEYS_MAP[oldKey];
    acc[newKey || oldKey] = value;

    return acc;
  }, {});

const initUserData = async (): Promise<undefined | PlainObject> => {
  const jwtFromCookie = Cookie.get(COOKIE_KEYS.JWT);

  if (isString(jwtFromCookie)) {
    const decodedData = await JWT.decode(jwtFromCookie);

    if (decodedData) {
      return normalizeKeys(decodedData);
    }
  }
};

export default defineNuxtPlugin({
  name: 'pp',
  parallel: true,
  async setup() {
    const dayjs = useDayjs();
    await handleUserIdQuery();
    await migrateSubmittedParams();
    const userData = ref<PlainObject>((await initUserData()) || {});

    watch(
      userData,
      async payload => {
        const token = await JWT.encode(payload);

        Cookie.set(COOKIE_KEYS.JWT, token, {
          expires: dayjs().add(1, 'year').toDate(),
        });
      },
      { deep: true },
    );

    const _get = (key: string) => get(userData.value, key);
    const _getBulk = (keys: string[]) =>
      keys.reduce<PlainObject>((acc, key) => {
        acc[key] = _get(key);
        return acc;
      }, {});

    const _update = (key: string, value: unknown) => {
      userData.value[key] = value;
    };
    const _updateBulk = (payload: PlainObject) => {
      for (const key in payload) {
        _update(key, payload[key]);
      }
    };

    return {
      provide: {
        pp: {
          get: _get,
          getBulk: _getBulk,

          update: _update,
          updateBulk: _updateBulk,
        },
      },
    };
  },
});
